import React from "react";
import "./EmptyTable.css";

import EmptyIcon from "/illustrations/emptytable.png";
import ProgressIcon from "/illustrations/under_progress.webp";
import { useTranslation } from "react-i18next";

export default function EmptyTable({ progress }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <div className="EmptyTable">
        <div className="EmptyTable__content">
          <div
            className={`EmptyTable__banner ${
              progress ? "Progress_img" : "Empty_img"
            }`}
          >
            <img src={progress ? ProgressIcon : EmptyIcon} alt="EmptyIcon" />
          </div>
          <div className="EmptyTable__message">
            <p className="page_body_fs">
              {progress ? t("under_progress") : t("no_records_found")}
            </p>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
