import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import SwitchButton from '../../../common/switchButton/SwitchButton';
import { modifyUsers } from '../../../../redux/userActions';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import { useTranslation } from 'react-i18next';

export default function LocationTable({ locations }) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label='simple table'>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('location_name')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('division')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('address')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('users')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('status')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('action')} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {locations ? (
              <>
                {locations?.length > 0 ? (
                  <>
                    {locations?.map((row, index) => (
                      <LocationTableRow row={row} index={index} />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={6}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={6} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t('loading')}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function LocationTableRow({ row, index }) {
  const [checked, setChecked] = React.useState(true);

  const handleCheckedChange = async (event, row) => {
    setChecked(event.target.checked);
    row.isActive = event.target.checked ? 'ACTIVE' : 'INACTIVE';
    await modifyUsers({
      userId: row._id,
      status: event.target.checked ? 'ACTIVE' : 'INACTIVE',
    });
  };

  return (
    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
      <TableCell>
        <FieldBody text={row.name} />
      </TableCell>
      <TableCell>
        <FieldBody text={row.division} />
      </TableCell>
      <TableCell>
        <FieldBody text={row.postalAddress} />
      </TableCell>
      <TableCell>
        <FieldBody text={row.usersCount} />
      </TableCell>
      <TableCell>
        <StatusLabel status={row?.isActive ? 'Active' : 'InActive'} />
      </TableCell>
      <TableCell>
        <SwitchButton
          value={checked}
          handleChange={(event) => handleCheckedChange(event, row)}
        />
      </TableCell>
    </TableRow>
  );
}
