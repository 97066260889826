import dayjs from 'dayjs';

export const formatNumber = (count) => {
  const absCount = Math.abs(count);
  if (absCount >= 1e9) {
    return (count / 1e9).toFixed(1) / 1 + 'B';
  } else if (absCount >= 1e6) {
    return (count / 1e6).toFixed(1) / 1 + 'M';
  } else if (absCount >= 1e3) {
    return (count / 1e3).toFixed(1) / 1 + 'K';
  } else {
    return count;
  }
};

export const calculateAge = (dob) => {
  let date1 = new Date();
  let date2 = new Date(dob);
  let diffDays = parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
  let year = parseInt(diffDays / 365);
  let month = parseInt(diffDays / 30);
  let day = parseInt(diffDays);
  if (year >= 1) {
    if (year == 1) {
      return year + ' year';
    } else return year + ' years';
  } else if (month >= 1) {
    if (month == 1) {
      return month + ' month';
    } else return month + ' months';
  } else {
    if (day == 1) {
      return day + ' day';
    } else return day + ' days';
  }
};

export const ageCalculate = (date) => {
  const date1 = dayjs();
  const date2 = dayjs(date);
  const years = date1.diff(date2, 'year');
  const months = date1.diff(date2.add(years, 'year'), 'month');
  return [years, months];
};

export const getDates = () => {
  const tomoro = dayjs().add(1, 'day');
  const tomorrow = tomoro.format('YYYY-MM-DD');
  const yester = dayjs().subtract(1);
  const yesterday = yester.format('YYYY-MM-DD');
  return [yesterday, tomorrow];
};

export const getDate = (date) => {
  const tomoro = dayjs(date).add(1, 'day');
  const tomorrow = tomoro.format('YYYY-MM-DD');
  const yester = dayjs(date).subtract(1, 'day');
  const yesterday = yester.format('YYYY-MM-DD');
  return [yesterday, tomorrow];
};

export const shipmentStatus = (status, type) => {
  let statusLabel = status;
  let statusColor = 'grey';

  switch (status) {
    case 'CREATED':
      statusLabel = 'Shipped';
      statusColor = 'blue';
      break;

    case 'RECEIVED':
      statusLabel = type === 'outbound' ? 'Delivered' : 'Received';
      statusColor = 'green';
      break;

    case 'PARTIALLY_RECEIVED':
      statusLabel =
        type === 'outbound' ? 'Partially Delivered' : 'Partially Received';
      statusColor = 'pink';
      break;
    default:
      statusLabel = status;
      statusColor = 'grey';
      break;
  }
  return [statusLabel, statusColor];
};

export const orderInStatus = (status) => {
  let statusLabel = status;
  let statusColor = 'grey';

  switch (status) {
    case 'CREATED':
      statusLabel = 'Received';
      statusColor = 'blue';
      break;

    case 'ACCEPTED':
      statusLabel = status;
      statusColor = 'green';
      break;

    case 'SHIPPED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'PARTIALLY_SHIPPED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'PARTIALLY_FULFILLED':
      statusLabel = status;
      statusColor = 'pink';
      break;

    case 'REJECTED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'CANCELED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'FULFILLED':
      statusLabel = status;
      statusColor = 'green';
      break;

    default:
      statusLabel = status;
      statusColor = 'grey';
      break;
  }
  return [statusLabel, statusColor];
};

export const orderOutStatus = (status) => {
  let statusLabel = status;
  let statusColor = 'grey';

  switch (status) {
    case 'CREATED':
      statusLabel = 'sent';
      statusColor = 'blue';
      break;

    case 'ACCEPTED':
      statusLabel = status;
      statusColor = 'green';
      break;

    case 'SHIPPED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'PARTIALLY_SHIPPED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'PARTIALLY_FULFILLED':
      statusLabel = status;
      statusColor = 'pink';
      break;

    case 'REJECTED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'CANCELED':
      statusLabel = status;
      statusColor = 'red';
      break;

    case 'FULFILLED':
      statusLabel = status;
      statusColor = 'active';
      break;

    default:
      statusLabel = status;
      statusColor = 'grey';
      break;
  }
  return [statusLabel, statusColor];
};
