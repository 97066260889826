import React from "react";
import "./TableField.css";

export default function FieldBody({ text, subtext, subtext2, color }) {
  return (
    <React.Fragment>
      <div className="FieldBody__space">
        <p className={`FieldBody__title_fs ${color}`}>{text}</p>
        {subtext && <p className="FieldBody__subtitle_fs">{subtext}</p>}
        {subtext2 && <p className="FieldBody__subtitle_fs">{subtext2}</p>}
      </div>
    </React.Fragment>
  );
}
