import React from "react";
import { useFormik } from "formik";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";

import FormControl from "@mui/material/FormControl";
import {
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { addLocation, adminLocations } from "../../../redux/userActions";
import { callPopup } from "../../../store/slices/popupSlice";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";

export default function AddLocation(props) {
  const {
    onClose,
    orgLevels,
    analyticUpdated,
    setAnalyticUpdated,
    open,
    ...other
  } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [locations, setLocations] = React.useState([]);
  const [userLevel, setUserLevel] = React.useState(null);

  const OrgLevelList = orgLevels?.filter((org) => org?.value !== "EPI");

  React.useEffect(() => {
    let ParentLevels = null;

    switch (userLevel) {
      case "DISTRICT":
        ParentLevels = "EPI";
        break;
      case "UPAZILA":
        ParentLevels = "DISTRICT";
        break;
      case "MUNICIPALITY":
        ParentLevels = "UPAZILA";
        break;
      case "CITY":
        ParentLevels = "MUNICIPALITY";
        break;
      case "ZONE":
        ParentLevels = "CITY";
        break;
      case "WARD":
        ParentLevels = "ZONE";
        break;
      case "OUTREACH_CENTERS":
        ParentLevels = "WARD";
        break;
      default:
        ParentLevels = null;
        break;
    }
    fetchData(ParentLevels);
  }, [userLevel]);

  const fetchData = async (level) => {
    if (level) {
      const res = await adminLocations(level);
      setLocations(res?.data?.data);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.level) errors.level = t("required");

    if (!values.location) errors.location = t("required");

    if (!values.parentLocation) errors.parentLocation = t("required");

    if (!values.country) errors.country = t("required");

    if (!values.division) errors.division = t("required");

    if (!values.district) errors.district = t("required");

    if (!values.address) errors.address = t("required");

    return errors;
  };

  const formik = useFormik({
    initialValues: {
      level: "",
      location: "",
      parentLocation: "",
      phone: "",
      country: "Bangladesh",
      division: "",
      district: "",
      address: "",
    },
    validate,
    onSubmit: async (values) => {
      const res = await addLocation({
        name: values.location,
        location: values.location,
        orgLevel: values.level,
        status: "ACTIVE",
        country: values.country,
        division: values.division,
        district: values.district,
        postalAddress: values.address,
        parentLocation: values.parentLocation,
      });
      if (res.data.success == true) {
        handlePopupClose();
        dispatch(
          callPopup({
            open: true,
            type: "success",
            page: "add-location",
            message: t("new_location_added"),
          })
        );
        setAnalyticUpdated(!analyticUpdated);
      } else {
        handlePopupClose();
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "add-location",
            title: res.data.message,
            message: res.data.data,
          })
        );
      }
    },
  });

  const handlePopupClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-paper": { width: "100%" },
        }}
        className="ModalPopup__dialog"
        maxWidth="sm"
        open={open}
        {...other}
      >
        <DialogTitle sx={{ padding: "0 !important" }}>
          <div className="ModalPopup__header">
            <div className="ModalPopup__header__title_fs">
              {t("add_location_title")}
            </div>
            <div className="ModalPopup__close_icon" onClick={handlePopupClose}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
        </DialogTitle>
        <form
          className="ModalPopup__form_container"
          onSubmit={formik.handleSubmit}
        >
          <DialogContent
            sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
          >
            <div className="ModalPopup__wrapper">
              <div className="ModalPopup__body">
                <div className="ModalPopup__form_section">
                  <div className="ModalPopup__label_header">
                    <h1 className="ModalPopup__form_section_heading_fs">
                      {t("add_location_subtitle1")}
                    </h1>
                  </div>

                  <div className="Input__table">
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("organization_level")}
                          <b>*</b>
                        </p>

                        <FormControl
                          fullWidth
                          placeholder="Select your Organization Level"
                        >
                          <Select
                            size="small"
                            className="mui_custom_input_field"
                            id="level"
                            name="level"
                            onChange={(e) => {
                              formik.handleChange(e);
                              setUserLevel(e.target.value);
                            }}
                            value={formik.values.level}
                          >
                            {OrgLevelList?.map((level, index) => (
                              <MenuItem key={index} value={level.value}>
                                {level.name}
                              </MenuItem>
                            ))}
                          </Select>
                          {formik.errors.level && formik.touched.level && (
                            <span className="Input__error_fs">
                              {formik.errors.level}
                            </span>
                          )}
                        </FormControl>
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("location_name")}
                          <b>*</b>
                        </p>
                        <input
                          type="text"
                          className="mi_custom_textfield"
                          id="location"
                          name="location"
                          maxLength={15}
                          onChange={formik.handleChange}
                          value={formik.values.location}
                        />
                        {formik.errors.location && formik.touched.location && (
                          <span className="Input__error_fs">
                            {formik.errors.location}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("parent_location")}
                          <b>*</b>
                        </p>
                        {/* <Select
                          className="mui_custom_input_field"
                          size="small"
                          placeholder="Select your Parent location"
                          id="parentLocation"
                          name="parentLocation"
                          onChange={formik.handleChange}
                          value={formik.values.parentLocation}
                        >
                          {locations?.map((loc, index) => (
                            <MenuItem key={index} value={loc._id}>
                              {loc.name}
                            </MenuItem>
                          ))}
                        </Select> */}
                        <Autocomplete
                          className="mui_custom_autocomplete"
                          options={locations}
                          getOptionLabel={(option) => option.name}
                          value={
                            locations.find(
                              (loc) => loc._id === formik.values.parentLocation
                            ) || null
                          }
                          onChange={(event, value) => {
                            formik.setFieldValue(
                              "parentLocation",
                              value ? value._id : null
                            );
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              size="small"
                              className="mui_custom_autocomplete"
                              variant="outlined"
                            />
                          )}
                        />
                        {formik.errors.parentLocation &&
                          formik.touched.parentLocation && (
                            <span className="Input__error_fs">
                              {formik.errors.parentLocation}
                            </span>
                          )}
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("phone_number")} <span>( {t("optional")} )</span>
                        </p>
                        {/* <input
                          type='text'
                          className='mi_custom_textfield'
                          id='phone'
                          name='phone'
                          onChange={formik.handleChange}
                          value={formik.values.phone}
                        /> */}
                        <PhoneInput
                          id="phone"
                          name="phone"
                          className="mi_custom_textfield vl-custom-phone-input"
                          international
                          countryCallingCodeEditable={false}
                          defaultCountry="BD"
                          maxLength={16}
                          onChange={(value) => {
                            formik.setFieldValue("phone", value);
                          }}
                          onBlur={formik.handleBlur("phone")}
                          value={formik.values.phone}
                        />
                        {formik.errors.phone && formik.touched.phone && (
                          <span className="Input__error_fs">
                            {formik.errors.phone}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ModalPopup__form_section">
                  <div className="ModalPopup__label_header">
                    <h1 className="ModalPopup__form_section_heading_fs">
                      {t("add_location_subtitle2")}
                    </h1>
                  </div>

                  <div className="Input__table">
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">{t("country")}</p>

                        <FormControl fullWidth>
                          <Select
                            // value={Country}
                            size="small"
                            className="mui_custom_input_field"
                            // onChange={(event) => {
                            //   setCountry(event.target.value);
                            // }}
                            id="country"
                            name="country"
                            onChange={formik.handleChange}
                            value={formik.values.country}
                          >
                            <MenuItem value="Bangladesh">Bangladesh</MenuItem>
                          </Select>
                          {formik.errors.country && formik.touched.country && (
                            <span className="Input__error_fs">
                              {formik.errors.country}
                            </span>
                          )}
                        </FormControl>
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("division")}
                          <b>*</b>
                        </p>

                        <FormControl fullWidth>
                          <Select
                            // value={Division}
                            size="small"
                            className="mui_custom_input_field"
                            // onChange={(event) => {
                            //   setDivision(event.target.value);
                            // }}
                            id="division"
                            name="division"
                            onChange={formik.handleChange}
                            value={formik.values.division}
                          >
                            <MenuItem value="Dhaka">Dhaka</MenuItem>
                          </Select>
                          {formik.errors.division &&
                            formik.touched.division && (
                              <span className="Input__error_fs">
                                {formik.errors.division}
                              </span>
                            )}
                        </FormControl>
                      </div>
                    </div>
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("district")}
                          <b>*</b>
                        </p>

                        <FormControl fullWidth>
                          <Select
                            // value={District}
                            size="small"
                            className="mui_custom_input_field"
                            // onChange={(event) => {
                            //   setDistrict(event.target.value);
                            // }}
                            id="district"
                            name="district"
                            onChange={formik.handleChange}
                            value={formik.values.district}
                          >
                            <MenuItem value="Dhaka">Dhaka</MenuItem>
                          </Select>
                          {formik.errors.district &&
                            formik.touched.district && (
                              <span className="Input__error_fs">
                                {formik.errors.district}
                              </span>
                            )}
                        </FormControl>
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("location_address")}
                          <b>*</b>
                        </p>
                        <input
                          type="text"
                          className="mi_custom_textfield"
                          id="address"
                          name="address"
                          maxLength={30}
                          onChange={formik.handleChange}
                          value={formik.values.address}
                        />
                        {formik.errors.address && formik.touched.address && (
                          <span className="Input__error_fs">
                            {formik.errors.address}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="ModalPopup__action_wrapper">
              <div className="ModalPopup_actions">
                <button
                  type="submit"
                  className="mi_btn mi_btn_medium mi_btn_primary"
                >
                  <span>{t("add_location_button")}</span>
                </button>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
