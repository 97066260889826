import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import AssignRole from '../../../components/assignRole/AssignRole';
import { modifyUsers } from '../../../../redux/userActions';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from 'react-i18next';

export default function PendingUsers({
  data,
  orgLevels,
  roles,
  setAnalyticUpdated,
  pagination,
  page,
  setPage,
  limit,
  setLimit,
  tableRef,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label='simple table' ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('user') + ' / ' + t('org_level')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('email_id')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('phone_number')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('location')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('created_on')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('action')} align='center' />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {data ? (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row, index) => (
                      <TableRowCard
                        row={row}
                        index={index}
                        roles={roles}
                        key={index}
                        orgLevels={orgLevels}
                        setAnalyticUpdated={setAnalyticUpdated}
                        t={t}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={6}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={6} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t('loading')}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={pagination?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function TableRowCard({ row, index, orgLevels, roles, setAnalyticUpdated, t }) {
  const [openAssignRolePopup, setOpenAssignRolePopup] = React.useState(false);
  const handleOpenAssignRolePopup = () => {
    setOpenAssignRolePopup(true);
  };

  const handleCloseAssignRolePopup = () => {
    setOpenAssignRolePopup(false);
  };

  const rejectUser = async (row) => {
    const res = await modifyUsers({
      userId: row?._id,
      status: 'REJECTED',
    });
    if (res.success === true) {
      setAnalyticUpdated((prev) => !prev);
    }
  };

  const location = row?.locations?.length > 0 ? row?.locations[0]?.name : '';
  return (
    <React.Fragment>
      <TableRow
        key={index}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <FieldBody
            text={row.firstName + ' ' + row.lastName}
            subtext={row.orgLevel}
            color='bold'
          />
        </TableCell>
        <TableCell>
          <FieldBody text={row.emailId} />
        </TableCell>
        <TableCell>
          <FieldBody text={row.phoneNumber} />
        </TableCell>

        <TableCell>
          <FieldBody text={location} />
        </TableCell>
        <TableCell>
          <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
        </TableCell>
        <TableCell>
          <div className='Table__actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={handleOpenAssignRolePopup}
            >
              <span>{t('accept')}</span>
            </button>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={() => rejectUser(row)}
            >
              <span>{t('reject_button')}</span>
            </button>
          </div>
        </TableCell>
      </TableRow>
      <AssignRole
        orgLevels={orgLevels}
        roles={roles}
        userId={row?._id}
        keepMounted
        open={openAssignRolePopup}
        onClose={handleCloseAssignRolePopup}
        setAnalyticUpdated={setAnalyticUpdated}
      />
    </React.Fragment>
  );
}
