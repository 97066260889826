import React from "react";
import { useFormik } from "formik";

// MUI ImportsImports
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import "./AddProduct.css";

import {
  Chip,
  DialogActions,
  DialogTitle,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { addProduct } from "../../../redux/userActions";
import { callPopup } from "../../../store/slices/popupSlice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";

export default function AddProduct(props) {
  const { onClose, open, manufacturers, products, ...other } = props;

  const [isDropDown, setIsDropDown] = useState(true);
  const [qtyPerUnitValue, setQtyPerUnitValue] = useState("");
  const [qtyPerUnitArray, setQtyPerUnitArray] = useState([]);
  const {t} = useTranslation()
  const dispatch = useDispatch();

  // const manufacturers = products
  //   ?.map((m) => m.manufacturers)
  //   .flat()
  //   .filter((obj, index, self) => {
  //     const uniqueIds = self?.map((item) => item?.id);
  //     return index === uniqueIds.indexOf(obj?.id);
  //   });

  const handleAddQty = () => {
    if(qtyPerUnitValue > 0 && !qtyPerUnitArray.includes(Number(qtyPerUnitValue)) ){
      setQtyPerUnitArray([...qtyPerUnitArray, parseInt(qtyPerUnitValue)]);
      setQtyPerUnitValue("")
    }else{
      toast.error(qtyPerUnitValue + " is already added !")
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.category) errors.category = t("required");

    if (!values.name) errors.name = t("required");

    if (!values.unit) errors.unit = t("required");

    return errors;
  };

  // 9955004433

  const formik = useFormik({
    initialValues: {
      category: "",
      name: "",
      manufacturerId: "",
      manufacturer: "",
      unit: "",
    },
    validate,
    onSubmit: async (values) => {
      const APIvalues = {
        name: values.name,
        shortName: values.name,
        type: values.category,
        units: values.unit,
        quantityPerUnit: qtyPerUnitArray,
      };

      if (values.manufacturerId !== "") {
        APIvalues.manufacturerId = values.manufacturerId;
      }

      if (values.manufacturer !== "") {
        APIvalues.manufacturer = { name: values.manufacturer };
      }
      const res = await addProduct(APIvalues);
      if (res.data.success == true) {
        handlePopupClose();
        dispatch(
          callPopup({
            open: true,
            type: "success",
            page: "add-product",
            message: t("new_product_added"),
          })
        );
      } else {
        handlePopupClose();
        dispatch(
          callPopup({
            open: true,
            type: "error",
            page: "add-product",
            title: res.data.message,
            message: res.data.data,
          })
        );
      }
      setIsDropDown(true);
    },
  });

  const handlePopupClose = () => {
    formik.resetForm();
    onClose();
    setIsDropDown(true);
    setQtyPerUnitValue("");
    setQtyPerUnitArray([]);
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          "& .MuiDialog-paper": { width: "100%" },
        }}
        className="ModalPopup__dialog"
        maxWidth="sm"
        open={open}
        {...other}
      >
        <DialogTitle sx={{ padding: "0 !important" }}>
          <div className="ModalPopup__header">
            <div className="ModalPopup__header__title_fs">{t("add_new_product")}</div>
            <div className="ModalPopup__close_icon" onClick={handlePopupClose}>
              <i className="fa-solid fa-xmark"></i>
            </div>
          </div>
        </DialogTitle>

        <form
          className="ModalPopup__form_container"
          onSubmit={formik.handleSubmit}
        >
          <DialogContent
            sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
          >
            <div className="ModalPopup__wrapper">
              <div className="ModalPopup__body">
                <div className="ModalPopup__form_section">
                  <div className="ModalPopup__label_header">
                    <h1 className="ModalPopup__form_section_heading_fs">
                      {t("product_information")}
                    </h1>
                  </div>

                  <div className="Input__table">
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">
                          {t("product_category")}<b>*</b>
                        </p>
                        <input
                          type="text"
                          className="mi_custom_textfield"
                          id="category"
                          name="category"
                          maxLength={15}
                          onChange={formik.handleChange}
                          value={formik.values.category}
                        />
                        {formik.errors.category && formik.touched.category && (
                          <span className="Input__error_fs">
                            {formik.errors.category}
                          </span>
                        )}
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                        {t("product_name")}<b>*</b>
                        </p>
                        <input
                          type="text"
                          className="mi_custom_textfield"
                          id="name"
                          name="name"
                          maxLength={35}
                          onChange={formik.handleChange}
                          value={formik.values.name}
                        />
                        {formik.errors.name && formik.touched.name && (
                          <span className="Input__error_fs">
                            {formik.errors.name}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="Input__row two_column">
                      <div className="Input__column">
                        <p className="Input__label_fs">{t("manufacturer")}</p>

                        <FormControl fullWidth>
                          {/* <Select
                            disabled={!isDropDown}
                            id="manufacturerId"
                            name="manufacturerId"
                            size="small"
                            className="mui_custom_input_field"
                            onChange={formik.handleChange}
                            value={formik.values.manufacturerId}
                          >
                            {manufacturers?.length &&
                              manufacturers?.map((row, index) => (
                                <MenuItem key={index + 1} value={row?._id}>
                                  {row?.name}
                                </MenuItem>
                              ))}
                          </Select> */}
                          <Autocomplete                            
                            className="mui_custom_autocomplete"
                            options={manufacturers}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value)=>{
                              formik.setFieldValue("manufacturerId", value?._id)
                            }}
                            renderInput={(params) => (
                            <TextField
                              {...params}
                              size='small'
                              className="mui_custom_autocomplete"
                              variant='outlined'
                            />
                            )}
                          />
                        </FormControl>
                      </div>
                      <div className="Input__column">
                        <p className="Input__label_fs">
                        {t("unit_of_measure")}<b>*</b>
                        </p>
                        <input
                          type="text"
                          className="mi_custom_textfield"
                          id="unit"
                          name="unit"
                          maxLength={15}
                          onChange={formik.handleChange}
                          value={formik.values.unit}
                        />
                        {formik.errors.unit && formik.touched.unit && (
                          <span className="Input__error_fs">
                            {formik.errors.unit}
                          </span>
                        )}
                      </div>                    
                    </div>
                    <div className="Input__row two_column">
                    <div className="Input__column">
                        <p className="Input__label_fs">
                        {t("quantity_per_unit")}<b>*</b>
                        </p>
                        <div className="input__flex_row">
                          <div className="qpu_input">
                            <input
                              maxLength={15}
                              onChange={(e) =>
                                setQtyPerUnitValue(e.target.value)
                              }
                              value={qtyPerUnitValue}
                            />

                            <div className="qpu_button" onClick={handleAddQty}>
                              <i className="fa-solid fa-check"></i>
                            </div>
                          </div>
                          <div className="qty_chip_group">
                            {qtyPerUnitArray?.map((qty) => (
                              <Chip
                                label={qty}
                                size="small"
                                variant="outlined"
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="ModalPopup__form_section">
                  <div className="ModalPopup__label_header">
                    <h1 className="ModalPopup__form_section_heading_fs">
                    {t("create_new_manufacturer")}
                    </h1>
                    {isDropDown ? (
                      <div
                        className="mi_btn mi_btn_link"
                        onClick={() => {
                          setIsDropDown(false);
                          formik.values.manufacturerId = "";
                        }}
                      >
                        <i className="fa-solid fa-plus"></i>
                        <span>{t("create_new")}</span>
                      </div>
                    ) : (
                      <div
                        className="mi_btn mi_btn_link"
                        onClick={() => {
                          setIsDropDown(true);
                          formik.values.manufacturer = "";
                        }}
                      >
                        <i className="fa-solid fa-plus"></i>
                        <span>{t("select_from_list")}</span>
                      </div>
                    )}
                  </div>

                  {!isDropDown && (
                    <div className="Input__table">
                      <div className="Input__row two_column">
                        <div className="Input__column">
                          <p className="Input__label_fs">{t("manufacturer_name")}</p>
                          <input
                            disabled={isDropDown}
                            type="text"
                            className="mi_custom_textfield"
                            id="manufacturer"
                            name="manufacturer"
                            maxLength={15}
                            onChange={formik.handleChange}
                            value={formik.values.manufacturer}
                          />
                          {formik.errors.manufacturer &&
                            formik.touched.manufacturer && (
                              <span className="Input__error_fs">
                                {formik.errors.manufacturer}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <div className="ModalPopup__action_wrapper">
              <div className="ModalPopup_actions">
                <button
                  type="submit"
                  className="mi_btn mi_btn_medium mi_btn_primary"
                >
                  <span>{t("add_product")}</span>
                </button>
              </div>
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}
