import React, { useRef } from "react";

// MUI Imports
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogTitle, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import Printer from "../printer/Printer";

export default function DataExport(props) {
  const {
    onClose,
    open,
    title,
    xport,
    tableComponent,
    printData,
    currentTab,
    ...other
  } = props;

  const { t } = useTranslation();

  const pdfPrintRef = useRef();
  const handlePrint = useReactToPrint({
    contentRef: pdfPrintRef,
  });

  const handleExport = () => {
    xport();
    onClose();
  };

  return (
    <Dialog
      sx={{
        "& .MuiDialog-paper": { width: "100%" },
      }}
      className='ModalPopup__dialog'
      maxWidth={"xl"}
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: "0 !important" }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            <div className='modal__heading_group'>
              <div className='modal__heading'>
                <h1 className='page__body_subheading_fs'>Preview Table Data</h1>
              </div>

              <div className='modal__quanity'> </div>
            </div>
          </div>
          <div
            className='ModalPopup__close_icon'
            onClick={() => {
              onClose();
            }}
          >
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: "0 !important", borderRadius: "0.6rem !important" }}
      >
        <div className='ModalPopup__body '>
          <section className='Page__main_wrapper' ref={pdfPrintRef}>
            <Printer title={title ? title : "List"}>
              <div className='printing_body'>{tableComponent}</div>
            </Printer>
          </section>
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <section className='InventoryOrders__actions_Popup'>
          <div>Total Records : {printData?.length}</div>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={handlePrint}
            >
              <i className='fa-solid fa-download'></i>
              <span>{t("generate_PDF")}</span>
            </button>
            <button
              onClick={handleExport}
              className='mi_btn mi_btn_medium mi_btn_primary'
            >
              Download
            </button>
          </div>
        </section>
      </DialogActions>
    </Dialog>
  );
}
