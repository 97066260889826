import React, { Fragment } from 'react';

export default function Printer({ children, title, id }) {
  return (
    <Fragment>
      <div className='printedPage__container'>
        <div className='printedPage__header'>
          <img
            src={`https://epi.vaccineledger.com/emailIcons/EmailBranding.png`}
            alt='Branding Logo'
          />
        </div>

        <div className='shipment__print_spacing'>
          <div className='shipment__print_flex_wrapper'>
            <div className='printedPage__subheader'>
              <h1>
                {title} {id && `:[ ${id} ]`}
              </h1>
            </div>
            <div className='print__copy_rights'>
              <p className='page__note_fs'>
                All Rights Reserved by MIS, DGHS, MOH & FW
              </p>
              <p className='page__gray_fs'>Powered By StaTwig &copy;2024</p>
            </div>
          </div>
        </div>
      </div>

      {children}

      {/* <div className='poweredSponser'>
        <p className='page__note_fs'>Powered By StaTwig &copy;2024</p>
      </div> */}
    </Fragment>
  );
}
