import React, { useEffect, useState } from 'react';
import { Formik } from 'formik';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@mui/material';
import {
  getPermissions,
  createRole,
  updateRole,
} from '../../../redux/userActions';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function AddRole(props) {
  const {
    onClose,
    open,
    analyticUpdated,
    setAnalyticUpdated,
    type,
    currentRole,
    ...other
  } = props;

  console.log(currentRole);

  const [permissions, setPermissions] = useState();
  const { t } = useTranslation();

  console.log(permissions);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const result = await getPermissions();
    setPermissions(result?.data);
  };

  const validate = (values) => {
    const errors = {};

    if (!values.role) {
      errors.role = t('required');
    }

    return errors;
  };

  const initialValues = {
    role: type === 'edit' ? currentRole?.name : '',
  };

  const handleFormSubmit = async (values) => {
    const addRoleData = {
      name: values.role,
      permissions: permissions,
    };

    const editRoleData = {
      roleId: currentRole?._id,
      name: values.role,
      permissions: currentRole?.permissions,
    };

    if (type === 'add') {
      const res = await createRole(addRoleData);
      if (res?.data?.success) {
        toast.success(t('role_created_msg'));
        setAnalyticUpdated((prev) => !prev);
        onClose();
      } else {
        toast.error(t('something_went_wrong'));
        onClose();
      }
    } else if (type === 'edit') {
      const editResult = await updateRole(editRoleData);
      if (editResult?.data?.success) {
        toast.success(t('role_updated_msg'));
        setAnalyticUpdated((prev) => !prev);
        onClose();
      } else {
        toast.error(t('something_went_wrong'));
        onClose();
      }
    }
  };

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '100%' },
        }}
        className='ModalPopup__dialog'
        maxWidth='sm'
        open={open}
        {...other}
      >
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={validate}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ padding: '0 !important' }}>
                <div className='ModalPopup__header'>
                  <div className='ModalPopup__header__title_fs'>
                    {type === 'add' ? t('add') : t('edit')} {t('role')}
                  </div>
                  <div
                    className='ModalPopup__close_icon'
                    onClick={() => {
                      handleReset();
                      onClose();
                    }}
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: '0 !important',
                  borderRadius: '0.6rem !important',
                }}
              >
                <div className='ModalPopup__form_container'>
                  <div className='ModalPopup__wrapper'>
                    <div className='ModalPopup__body'>
                      <div className='ModalPopup__form_section'>
                        <div className='ModalPopup__label_header'>
                          <h1 className='ModalPopup__form_section_heading_fs'>
                            {t('role_information')}
                          </h1>
                        </div>

                        <div className='Input__table'>
                          <div className='Input__row'>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('role')}
                                <b>*</b>
                              </p>
                              <input
                                type='text'
                                className='mi_custom_textfield'
                                id='role'
                                name='role'
                                maxLength={15}
                                onChange={handleChange}
                                value={values.role}
                              />
                              {errors.role && touched.role && (
                                <span className='Input__error_fs'>
                                  {errors.role}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className='ModalPopup__action_wrapper'>
                  <div className='ModalPopup_actions'>
                    <button
                      type='submit'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span> {t('submit')}</span>
                    </button>
                  </div>
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}
