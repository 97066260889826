import React, { useMemo } from 'react';
import { Formik } from 'formik';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { Autocomplete, Chip, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { DialogActions, DialogTitle, MenuItem, Select } from '@mui/material';
import AddLocation from '../addLocation/AddLocation';
import {
  addUser,
  adminLocations,
  getOrganizationLevel,
} from '../../../redux/userActions';
import { useDispatch } from 'react-redux';
import { callPopup } from '../../../store/slices/popupSlice';
import PhoneInputField from '../../../shared/connection/signup/PhoneInputField';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

export default function AddUser(props) {
  const {
    onClose,
    open,
    roles,
    analyticUpdated,
    setAnalyticUpdated,
    ...other
  } = props;
  const [duplicate, setDuplicate] = React.useState(false);
  const [openPopup, setOpenPopup] = React.useState(false);
  const [locations, setLocations] = React.useState([]);
  const [orgLevels, setOrgLevels] = React.useState();
  const [userLevel, setUserLevel] = React.useState(null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    fetchData();
    setOpenPopup(false);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const re = await getOrganizationLevel();
      setOrgLevels(re?.data);
    };
    fetchData();
  }, []);

  React.useEffect(() => {
    fetchData(userLevel);
  }, [userLevel]);

  const fetchData = async (level) => {
    if (level) {
      const res = await adminLocations(level);
      setLocations(res?.data?.data);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.firstName) {
      errors.firstName = t('required');
    }
    if (!values.lastName) {
      errors.lastName = t('required');
    }
    if (values.email) {
      if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
        errors.email = t('valid_email_error');
      }
    }
    if (!values.email && !values.phone) {
      errors.email = t('email_phone_error');
    }
    if (!values.level) {
      errors.level = t('required');
    }
    if (!values.role) {
      errors.role = t('required');
    }
    if (!values.location) {
      errors.location = t('required');
    }

    return errors;
  };

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    level: '',
    role: '',
    location: null,
  };

  const handleFormSubmit = async (values, { resetForm }) => {
    const registerBody = {
      firstName: values.firstName,
      lastName: values.lastName,
      orgLevel: values.level,
      roleId: values.role,
      locationIds: values.location,
    };
    if (values.email !== '') {
      registerBody['email'] = values.email;
    }

    if (values.phone !== '') {
      registerBody['phoneNumber'] = values.phone;
    }

    const res = await addUser(registerBody);

    if (res.success) {
      dispatch(
        callPopup({
          open: true,
          type: 'success',
          page: 'add-user',
          message: t('new_user_added'),
        })
      );
      setAnalyticUpdated(!analyticUpdated);
      resetForm();
      onClose();
    } else {
      toast.error(res.data.message);
      dispatch(
        callPopup({
          open: true,
          type: 'error',
          page: 'add-user',
          title: res.data.message,
          message: res.data.data,
        })
      );
    }
  };

  const districtOptions = useMemo(
    () =>
      Array.isArray(locations)
        ? locations.map((district) => ({
            id: district?._id,
            name: district?.name,
          }))
        : [],
    [locations]
  );

  return (
    <React.Fragment>
      <Dialog
        sx={{
          '& .MuiDialog-paper': { width: '100%' },
        }}
        className='ModalPopup__dialog'
        maxWidth='sm'
        open={open}
        {...other}
      >
        <Formik
          initialValues={initialValues}
          validate={validate}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            handleReset,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle sx={{ padding: '0 !important' }}>
                <div className='ModalPopup__header'>
                  <div className='ModalPopup__header__title_fs'>
                    {t('add_user_title')}
                  </div>
                  <div
                    className='ModalPopup__close_icon'
                    onClick={() => {
                      handleReset();
                      onClose();
                    }}
                  >
                    <i className='fa-solid fa-xmark'></i>
                  </div>
                </div>
              </DialogTitle>
              <DialogContent
                sx={{
                  padding: '0 !important',
                  borderRadius: '0.6rem !important',
                }}
              >
                <div className='ModalPopup__form_container'>
                  <div className='ModalPopup__wrapper'>
                    <div className='ModalPopup__body'>
                      <div className='ModalPopup__form_section'>
                        <div className='ModalPopup__label_header'>
                          <h1 className='ModalPopup__form_section_heading_fs'>
                            {t('add_user_subtitle1')}
                          </h1>
                        </div>

                        <div className='Input__table'>
                          <div className='Input__row two_column'>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('first_name')}
                                <b>*</b>
                              </p>
                              <input
                                type='text'
                                className='mi_custom_textfield'
                                id='firstName'
                                name='firstName'
                                maxLength={50}
                                onChange={handleChange}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /\s/g,
                                    ''
                                  ))
                                }
                                value={values.firstName}
                              />
                              {errors.firstName && touched.firstName && (
                                <span className='Input__error_fs'>
                                  {errors.firstName}
                                </span>
                              )}
                            </div>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('last_name')}
                                <b>*</b>
                              </p>
                              <input
                                type='text'
                                className='mi_custom_textfield'
                                id='lastName'
                                name='lastName'
                                maxLength={50}
                                onChange={handleChange}
                                onInput={(e) =>
                                  (e.target.value = e.target.value.replace(
                                    /\s/g,
                                    ''
                                  ))
                                }
                                value={values.lastName}
                              />
                              {errors.lastName && touched.lastName && (
                                <span className='Input__error_fs'>
                                  {errors.lastName}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className='Input__row two_column'>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('email_address')}
                              </p>
                              <input
                                type='text'
                                className='mi_custom_textfield'
                                id='email'
                                name='email'
                                onChange={(e) => {
                                  setDuplicate(false);
                                  handleChange(e);
                                }}
                                value={values.email}
                              />

                              {touched.email && duplicate && (
                                <span className='Input__error_fs'>
                                  {t('email_exist_msg')}
                                </span>
                              )}
                            </div>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('phone_number')}
                              </p>
                              <PhoneInputField
                                id='phone'
                                name='phone'
                                className='mi_custom_textfield vl-custom-phone-input'
                                international
                                countryCallingCodeEditable={false}
                                defaultCountry='BD'
                                maxLength={20}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.phone}
                              />
                            </div>
                          </div>
                          <div className='Input__row two_column'>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('organization_level')}
                                <b>*</b>
                              </p>

                              <FormControl fullWidth>
                                <Select
                                  size='small'
                                  className='mui_custom_input_field'
                                  id='level'
                                  name='level'
                                  onChange={(e) => {
                                    handleChange(e);
                                    setUserLevel(e.target.value);
                                  }}
                                  value={values.level}
                                >
                                  {orgLevels?.map((level, index) => (
                                    <MenuItem key={index} value={level.value}>
                                      {level.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.level && touched.level && (
                                  <span className='Input__error_fs'>
                                    {errors.level}
                                  </span>
                                )}
                              </FormControl>
                            </div>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('user_role')}
                                <b>*</b>
                              </p>

                              <FormControl fullWidth>
                                <Select
                                  className='mui_custom_input_field'
                                  size='small'
                                  id='role'
                                  name='role'
                                  onChange={handleChange}
                                  value={values.role}
                                >
                                  {roles?.map((level, index) => (
                                    <MenuItem key={index} value={level?._id}>
                                      {level?.name}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.role && touched.role && (
                                  <span className='Input__error_fs'>
                                    {errors.role}
                                  </span>
                                )}
                              </FormControl>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className='ModalPopup__form_section'>
                        <div className='ModalPopup__label_header'>
                          <h1 className='ModalPopup__form_section_heading_fs'>
                            {t('add_user_subtitle2')}
                          </h1>
                          <button
                            className='mi_btn mi_btn_link'
                            onClick={(e) => {
                              e.preventDefault();
                              handleOpenPopup();
                            }}
                          >
                            <i className='fa-solid fa-plus'></i>
                            <span>{t('add_loc_button')}</span>
                          </button>
                        </div>

                        <div className='Input__table'>
                          <div className='Input__row two_column'>
                            <div className='Input__column'>
                              <p className='Input__label_fs'>
                                {t('location')}
                                <b>*</b>
                              </p>
                              <FormControl fullWidth>
                                <Autocomplete
                                  multiple
                                  id='tags-filled'
                                  size='small'
                                  className='mui_custom_autocomplete'
                                  options={districtOptions}
                                  getOptionLabel={(option) => option.name}
                                  isOptionEqualToValue={(option, value) =>
                                    option?.id === value?.id
                                  }
                                  value={districtOptions?.filter((option) =>
                                    values?.location?.includes(option?.id)
                                  )}
                                  onChange={(event, value) => {
                                    setFieldValue(
                                      'location',
                                      value.map((option) => option?.id)
                                    );
                                  }}
                                  renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                      <Chip
                                        variant='outlined'
                                        label={option?.name}
                                        {...getTagProps({ index })}
                                        key={option?.id}
                                      />
                                    ))
                                  }
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      className='mui_custom_autocomplete'
                                      size='small'
                                      variant='outlined'
                                      placeholder={
                                        values?.location?.length > 0
                                          ? ''
                                          : 'Select locations'
                                      }
                                      sx={{ borderRadius: '8px' }}
                                    />
                                  )}
                                />

                                {errors.location && touched.location && (
                                  <span className='Input__error_fs'>
                                    {errors.location}
                                  </span>
                                )}
                              </FormControl>
                              {/* <FormControl fullWidth>
                                <Autocomplete
                                  className='mui_custom_autocomplete'
                                  options={locations}
                                  getOptionLabel={(option) => option.name}
                                  value={
                                    locations.find(
                                      (loc) => loc._id === values.location
                                    ) || null
                                  }
                                  onChange={(event, value) => {
                                    setFieldValue(
                                      'location',
                                      value ? value._id : null
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      size='small'
                                      className='mui_custom_autocomplete'
                                      variant='outlined'
                                    />
                                  )}
                                />
                                {errors.location && touched.location && (
                                  <span className='Input__error_fs'>
                                    {errors.location}
                                  </span>
                                )}
                              </FormControl> */}
                            </div>
                            <div className='Input__column'></div>
                            {errors.email &&
                              (touched.email || touched.phone) && (
                                <span className='Input__error_fs'>
                                  {errors.email}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <div className='ModalPopup__action_wrapper'>
                  <div className='ModalPopup_actions'>
                    <button
                      type='submit'
                      className='mi_btn mi_btn_medium mi_btn_primary'
                    >
                      <span>{t('add_user_button')}</span>
                    </button>
                  </div>
                </div>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>

      <AddLocation
        keepMounted
        open={openPopup}
        locations={locations}
        orgLevels={orgLevels}
        onClose={handleClosePopup}
      />
    </React.Fragment>
  );
}
