import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../shared/utils/tableField/FieldBody';
import Filter from '/icons/all/Filter.svg';
import Search from '/icons/all/Search.svg';
import Export from '/icons/all/Export.svg';
import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TablePagination,
  TextField,
} from '@mui/material';
import { toast } from 'react-hot-toast';
import {
  getLocations,
  getOrganizationLevel,
  updateMinMax,
} from '../../../redux/userActions';
import { useTranslation } from 'react-i18next';
import { getAllInventoryProducts } from '../../../redux/inventoryActions';
import { useRef, useCallback } from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';

export default function Threshold() {
  const { t } = useTranslation();

  // Data Fetch
  const [levelsList, setLevelsList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const resultLevel = await getOrganizationLevel();
      setLevelsList(resultLevel?.data);
    }
    fetchData();
  }, []);

  // Local States
  const [orgLevel, setOrgLevel] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState();

  useEffect(() => {
    async function fetchData(level) {
      if (level) {
        const res = await getLocations(level);
        setLocationList(res?.data);
      }
    }
    fetchData(orgLevel);
  }, [orgLevel]);

  const [allProducts, setAllProducts] = useState([]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Get All Products
  useEffect(() => {
    async function fetchData(id, page, limit) {
      if (id) {
        const resultProd = await getAllInventoryProducts(id, null, page, limit);
        setAllProducts(resultProd?.data);
      }
    }
    fetchData(selectedLocation, page, limit);
  }, [selectedLocation, page, limit]);

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `Threshold_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, []);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <div className='Page__headline'>
              <i className='bx bx-box'></i>
              <h1 className='Page__headline_title_fs'>{t('threshold')}</h1>
            </div>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              <img src={Search} className='Search__icon' />
              <input type='text' placeholder='Search' />
            </article>
            <button className='mi_btn mi_btn_medium mi_btn_outline'>
              <img src={Filter} className='Search__icon' />
              <span>{t('filter')}</span>
            </button>
            <button
              onClick={xport}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              <img src={Export} className='Search__icon' />
              <span>{t('export')}</span>
            </button>
          </div>
        </div>
        <div className='AddCampaign__container'>
          <div className=' Input__table'>
            <div className='Input__row two_column'>
              <div className='Input__column'>
                <p className='Input__label_fs'>{t('org_level')}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    className='mui_custom_input_field'
                    onChange={(e) => {
                      setOrgLevel(e.target.value);
                      setSelectedLocation();
                    }}
                  >
                    {levelsList?.map((item, index) => (
                      <MenuItem key={index} value={item?.value}>
                        {item?.value}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className='Input__column'>
                <p className='Input__label_fs'>{t('location')}</p>
                <FormControl fullWidth>
                  <Select
                    size='small'
                    className='mui_custom_input_field'
                    onChange={(e) => {
                      setSelectedLocation(e.target.value);
                    }}
                  >
                    {locationList?.map((item, index) => (
                      <MenuItem key={index} value={item?._id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
        <div className='Page__table_space'>
          <TableContainer>
            <Table
              sx={{ minWidth: 485 }}
              aria-label='simple table'
              ref={tableRef}
            >
              <TableHead className='mi_table__head'>
                <TableRow>
                  <TableCell>
                    <FieldHead title='Product Category' />
                  </TableCell>
                  <TableCell>
                    <FieldHead title='Product Name' />
                  </TableCell>

                  <TableCell>
                    <FieldHead title='Minimum' />
                  </TableCell>

                  <TableCell>
                    <FieldHead title='Maximum' />
                  </TableCell>

                  <TableCell align='center'>
                    <FieldHead title='Action' align={'center'} />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedLocation ? (
                  <>
                    {allProducts?.data?.length > 0 ? (
                      allProducts?.data?.map((row) => (
                        <ThresholdBody t={t} row={row} />
                      ))
                    ) : (
                      <TableRow className='mi_table__body_No_padding'>
                        <TableCell style={{ padding: '2rem' }} colSpan={7}>
                          <EmptyTable />
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={7}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
            <TablePagination
              component='div'
              count={allProducts?.totalRecords}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={limit}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
        </div>
      </section>
    </React.Fragment>
  );
}

function ThresholdBody({ t, row }) {
  const [minValue, setMinValue] = useState(row?.threshold?.min || 0);
  const [maxValue, setMaxValue] = useState(row?.threshold?.max || 0);

  const [btnStatus, setBtnStatus] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const apiData = {
      inventoryId: row?.inventoryId,
      productId: row?.productDetails._id,
      min: minValue,
      max: maxValue,
    };
    if (minValue > 0 && maxValue > 0) {
      const res = await updateMinMax(apiData);
      if (res?.success == true) {
        toast.success('Threshold Updated');
        setBtnStatus(false);
      } else {
        toast.error('Something went wrong');
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    setBtnStatus(true);
  };
  return (
    <TableRow
      key={row?.id}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell>
        <FieldBody text={row?.productDetails?.type} />
      </TableCell>
      <TableCell>
        <FieldBody text={row?.productDetails?.name} color='bold' />
      </TableCell>

      <TableCell>
        <div className='edit_input_wrap'>
          <input
            type='text'
            value={minValue}
            onChange={(e) => setMinValue(e.target.value)}
            disabled={btnStatus ? false : true}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className='edit_input_wrap'>
          <input
            type='text'
            value={maxValue}
            onChange={(e) => setMaxValue(e.target.value)}
            disabled={btnStatus ? false : true}
          />
        </div>
      </TableCell>
      <TableCell>
        <div className='Table__actions'>
          {btnStatus ? (
            <button
              className='mi_btn mi_btn_small mi_btn_secondary'
              onClick={(e) => {
                handleSubmit(e);
              }}
            >
              <span>{t('submit')}</span>
            </button>
          ) : (
            <button
              className='mi_btn mi_btn_small mi_btn_secondary'
              onClick={(e) => {
                handleEdit(e);
              }}
            >
              <span>{t('edit')}</span>
            </button>
          )}
        </div>
      </TableCell>
    </TableRow>
  );
}
