import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TotalOrgExport({
  printData,
  tableRef,
  locations,
  users,
}) {
  const { t } = useTranslation();

  return (
    <table style={tableStyle} ref={tableRef}>
      <thead className='mi_table__head'>
        <tr>
          <th style={thStyle}>{t('organization_level')}</th>
          <th style={thStyle}>{t('total_users')}</th>
          <th style={thStyle}>{t('no_of_locations')}</th>
          <th style={thStyle}>{t('status')}</th>
          <th style={thStyle}>{t('created_on')}</th>
        </tr>
      </thead>
      <tbody className='mi_table__body'>
        {printData?.map((row, index) => (
          <TableRowCard
            row={row}
            key={index}
            i={index}
            locations={locations}
            users={users}
          />
        ))}
      </tbody>
    </table>
  );
}

function TableRowCard({ row, i, locations, users }) {
  return (
    <tr key={i}>
      <td style={tdStyle}>{row?.name || '--'}</td>
      <td style={tdStyle}>
        {users?.filter((u) => u.orgLevel == row?.value)?.length || '--'}
      </td>
      <td style={tdStyle}>
        {locations?.filter((l) => l.orgLevel == row?.value)?.length || '--'}
      </td>

      <td style={tdStyle}>{row.isActive ? 'Active' : 'In Active' || '--'}</td>

      <td style={tdStyle}>
        {row?.createdAt ? new Date(row.createdAt).toLocaleDateString() : '--'}
      </td>
    </tr>
  );
}
const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const tdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...tdStyle,
  backgroundColor: '#f2f2f2',
};
