import React from 'react';
import { useTranslation } from 'react-i18next';

export default function TotalUserExport({ printData, tableRef }) {
  const { t } = useTranslation();

  return (
    <table style={tableStyle} ref={tableRef}>
      <thead className='mi_table__head'>
        <tr>
          <th style={thStyle}>{t('user')}</th>
          <th style={thStyle}>{t('org_level')}</th>
          <th style={thStyle}>{t('email_id')}</th>
          <th style={thStyle}>{t('phone_number')}</th>
          <th style={thStyle}>{t('location')}</th>
          <th style={thStyle}>{t('role')}</th>
          <th style={thStyle}>{t('status')}</th>
          <th style={thStyle}>{t('created_on')}</th>
        </tr>
      </thead>
      <tbody className='mi_table__body'>
        {printData?.map((row, index) => (
          <TableRowCard row={row} key={index} i={index} />
        ))}
      </tbody>
    </table>
  );
}

function TableRowCard({ row, i }) {
  const location = row?.locations?.length > 0 ? row?.locations[0]?.name : '--';

  return (
    <tr key={i}>
      <td style={tdStyle}>
        {row.firstName && row.lastName
          ? `${row.firstName} ${row.lastName}`
          : '--'}
      </td>
      <td style={tdStyle}>{row.orgLevel || '--'}</td>
      <td style={tdStyle}>{row.emailId || '--'}</td>
      <td style={tdStyle}>{row.phoneNumber || '--'}</td>
      <td style={tdStyle}>{location}</td>
      <td style={tdStyle}>{row?.role?.name || '--'}</td>
      <td style={tdStyle}>{row.accountStatus || '--'}</td>
      <td style={tdStyle}>
        {row?.createdAt ? new Date(row.createdAt).toLocaleDateString() : '--'}
      </td>
    </tr>
  );
}
const tableStyle = {
  borderCollapse: 'collapse',
  width: '100%',
};

const tdStyle = {
  border: '1px solid black',
  padding: '8px',
  textAlign: 'left',
  fontSize: '10px',
};

const thStyle = {
  ...tdStyle,
  backgroundColor: '#f2f2f2',
};
