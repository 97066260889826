import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Popper from "@mui/material/Popper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Autocomplete, { autocompleteClasses } from "@mui/material/Autocomplete";
import ButtonBase from "@mui/material/ButtonBase";
import InputBase from "@mui/material/InputBase";

const StyledAutocompletePopper = styled("div")(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: "none",
    margin: 0,
    color: "inherit",
    fontSize: 13,
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: "#fff",
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: "auto",
      alignItems: "flex-start",
      padding: 8,
      borderBottom: `1px solid #eaecef`,
      '&[aria-selected="true"]': {
        backgroundColor: "transparent",
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: "relative",
  },
}));

function PopperComponent(props) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
  anchorEl: PropTypes.any,
  disablePortal: PropTypes.bool,
  open: PropTypes.bool.isRequired,
};

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid #e1e4e8`,
  boxShadow: `0 8px 24px rgba(149, 157, 165, 0.2)`,
  borderRadius: 6,
  width: 300,
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: "#24292e",
  backgroundColor: "#fff",
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 10,
  width: "100%",
  borderBottom: `1px solid #eaecef`,
  "& input": {
    borderRadius: 4,
    backgroundColor: "#fff",
    padding: 8,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    border: `1px solid #eaecef`,
    fontSize: 14,
    "&:focus": {
      boxShadow: `0px 0px 0px 3px rgba(3, 102, 214, 0.3)`,
      borderColor: "#0366d6",
    },
  },
}));

const Button = styled(ButtonBase)(({ theme }) => ({
  fontSize: 13,
  width: "100%",
  textAlign: "left",
  paddingBottom: 8,
  color: "#586069",
  fontWeight: 600,
  "&:hover,&:focus": {
    color: "#0366d6",
  },
  "& span": {
    width: "100%",
  },
  "& svg": {
    width: 16,
    height: 16,
  },
}));

export default function ColumnFilter({
  open,
  anchorEl,
  handleClose,
  labels,
  value,
  setValue,
}) {
  return (
    <StyledPopper open={open} anchorEl={anchorEl} placement='bottom-start'>
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Autocomplete
            open
            multiple
            onClose={(event, reason) => {
              if (reason === "escape") {
                handleClose();
              }
            }}
            value={value}
            onChange={(event, newValue, reason) => {
              if (
                event.type === "keydown" &&
                event.key === "Backspace" &&
                reason === "removeOption"
              ) {
                return;
              }
              const uniqueValues = [...new Set(newValue)];
              setValue(uniqueValues);
            }}
            disableCloseOnSelect
            PopperComponent={PopperComponent}
            renderTags={() => null}
            noOptionsText='No labels'
            options={labels}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <div className='Filter__options'>
                  <input
                    type='checkbox'
                    checked={selected}
                    onChange={() => {
                      const isChecked = !value.includes(option);
                      setValue((prevValue) => {
                        if (isChecked) {
                          return [...prevValue, option];
                        } else {
                          return prevValue.filter((label) => label !== option);
                        }
                      });
                    }}
                  />
                  <p className='page__note_fs text_caps'>{option.name}</p>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <StyledInput
                ref={params.InputProps.ref}
                inputProps={params.inputProps}
                autoFocus
                placeholder='Filter labels'
              />
            )}
          />
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
}
