import { API_URL } from '../config';
import axios from 'axios';

export const updateProfile = async (data) => {
  try {
    const result = await axios.post(API_URL.updateProfileUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getProducts = async (type) => {
  try {
    const result = await axios.get(API_URL.productsUrl, {
      params: { type: type },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getRoles = async () => {
  try {
    const result = await axios.get(API_URL.rolesUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createRole = async (data) => {
  try {
    const result = await axios.post(API_URL.rolesUrl, data);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const updateRole = async (data) => {
  try {
    const result = await axios.put(API_URL.rolesUrl, data);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const getPermissions = async () => {
  try {
    const result = await axios.get(API_URL.permissionsUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const modifyRoles = async (data) => {
  try {
    const result = await axios.put(API_URL.rolesUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getCountries = async () => {
  try {
    const result = await axios.get(API_URL.countriesUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getAdminOrgLevels = async () => {
  try {
    const result = await axios.get(API_URL.adminOrgLevelsUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const register = async (data) => {
  try {
    const result = await axios.post(API_URL.registerUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const login = async (data) => {
  try {
    const result = await axios.post(API_URL.loginUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const loginHRIS = async (data) => {
  try {
    const result = await axios.post(API_URL.loginHrisUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const logout = async () => {
  try {
    const result = await axios.get(API_URL.logoutUrl);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const sendOtp = async (data, language) => {
  try {
    if (language === undefined) {
      language = 'en';
    }
    const result = await axios.post(API_URL.sendOtpUrl, data, {
      headers: {
        'Accept-Language': language,
      },
    });
    return result;
  } catch (e) {
    return e.response;
  }
};

export const verifyOtp = async (data, language) => {
  try {
    if (language === undefined) {
      language = 'en';
    }
    const result = await axios.post(API_URL.verifyOtpUrl, data, {
      headers: {
        'Accept-Language': language,
      },
    });
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getOrganizationLevel = async () => {
  try {
    const result = await axios.get(API_URL.getOrganizationLevelsUrl);
    return result.data;
  } catch (e) {
    return e;
  }
};

export const updatePwd = async (data) => {
  try {
    const result = await axios.put(API_URL.updatePwdUrl, data);
    return result.data;
  } catch (e) {
    return e;
  }
};

export const adminUsers = async (status, page, limit) => {
  try {
    const result = await axios.get(API_URL.adminUsers, {
      params: { status: status, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e;
  }
};

export const adminDashboardSummary = async () => {
  try {
    const result = await axios.get(`${API_URL.adminDashboardSummaryUrl}`);
    return result.data;
  } catch (e) {
    return e;
  }
};

export const adminLocations = async (level) => {
  try {
    const result = await axios.get(API_URL.adminLocationsUrl, {
      params: { orgLevel: level },
    });
    return result;
  } catch (e) {
    return e.response;
  }
};

export const addUser = async (data) => {
  try {
    const result = await axios.post(API_URL.adminUsers, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const modifyUsers = async (data) => {
  try {
    const result = await axios.patch(API_URL.adminUsers, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const addLocation = async (data) => {
  try {
    const result = await axios.post(API_URL.adminLocationsUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const addProduct = async (data) => {
  try {
    const result = await axios.post(API_URL.addProductUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const updateWarehouse = async (data, id) => {
  try {
    const result = await axios.post(API_URL.updateWarehouse + id, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const registerUser = async (data, language) => {
  try {
    if (language === undefined) {
      language = 'en';
    }
    const result = await axios.post(API_URL.registerUrl, data, {
      headers: {
        'Accept-Language': language,
      },
    });
    return result;
  } catch (e) {
    return e.response;
  }
};

export const forgotPassword = async (data) => {
  try {
    const result = await axios.post(API_URL.forgotPasswordUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getUserInfo = async () => {
  try {
    const result = await axios.get(API_URL.userInfoUrl);
    return result.data;
  } catch (error) {
    return e.response;
  }
};

export const getActiveWareHouses = async () => {
  try {
    const result = await axios.get(API_URL.userInfoUrl);
    return result.data.data.warehouses;
  } catch (e) {
    return e.response;
  }
};

export const getUserInfoUpdated = async () => {
  try {
    const result = await axios.get(API_URL.userInfoUrl);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getWarehouseById = async (id) => {
  try {
    const result = await axios.get(API_URL.getWarehouseById + id);
    return result;
  } catch (e) {
    return e.response;
  }
};

// Alert Modal Popup Verification
export const getAlertModalData = async (id) => {
  try {
    const result = await axios.get(API_URL.requestModalAlertUrl + id);
    return result.data.data;
  } catch (e) {
    return e.response;
  }
};

export const updateAlertModalData = async (id, status) => {
  try {
    const result = await axios.get(
      API_URL.updateStatusModalAlert + id + '&status=' + status
    );
    return result.data.data;
  } catch (e) {
    return e.response;
  }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: AUTH_SUCCESS,
    payload: decoded,
  };
};

// Set logged in user
export const logoutUser = () => {
  localStorage.removeItem('theLedgerToken');
  localStorage.removeItem('location');
  if (window?.torus) {
    window.torus.logout();
  }
  window.location.href = '/';
  return {
    type: AUTH_ERROR,
  };
};

// Set user profile
export const setProfile = (data) => {
  return {
    type: PROFILE_SUCCESS,
    payload: data,
  };
};

// Set user profile
export const setAllUsers = (data) => {
  return {
    type: GET_ALL_USERS_SUCCESS,
    payload: data,
  };
};

//set location from top panel dropdown
export const setUserLocation = (data) => {
  return {
    type: SET_USER_LOCATION,
    payload: data,
  };
};

export const getAllUsers = () => {
  return async (dispatch) => {
    const result = await axios.get(API_URL.getAllUsersUrl);
    dispatch(setAllUsers(result.data.data));
    return result;
  };
};

export const postUserLocation = async (data) => {
  try {
    const result = await axios.post(API_URL.locationUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const getAllManageAlerts = async () => {
  try {
    const result = await axios.get(API_URL.getAllManageAlertsUrl);
    return result.data.data || [];
  } catch (e) {
    return [];
  }
};

export const createUpdateNewAlert = async (data) => {
  try {
    const result = await axios.post(API_URL.createUpdateAlertsUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const googleLogin = async (data) => {
  try {
    const result = await axios.post(API_URL.googleLoginUrl, data);
    return result;
  } catch (e) {
    return e.response;
  }
};

export const verifyAuth = async (data) => {
  try {
    const result = await axios.post(API_URL.verifyAuth, data);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const newDemoRequest = async (data) => {
  try {
    const result = await axios.post(API_URL.newDemoRequestUrl, data);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const validateRequest = async (data) => {
  try {
    const result = await axios.post(API_URL.validateRequestUrl, data);
    return result;
  } catch (err) {
    return err.response;
  }
};

export const getUserRole = async (id) => {
  try {
    const result = await axios.get(`${API_URL.userRolesUrl}/${id}`);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getNotifications = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.notificationUrl, {
      params: { page, limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateAlertPreference = async (data) => {
  try {
    const result = await axios.put(API_URL.alertsUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

// Get Locations

export const getLocations = async (levels) => {
  try {
    const result = await axios.get(API_URL.locationUrl, {
      params: { orgLevel: levels },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getChildrenOrgLevels = async () => {
  try {
    const result = await axios.get(API_URL.childOrgLevelUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getChildrenLocations = async (orgLevel) => {
  try {
    const result = await axios.get(API_URL.childLocationsUrl, {
      params: { orgLevel },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

// Min Max
export const updateMinMax = async (data) => {
  try {
    const result = await axios.post(API_URL.thresholdUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const changeUserLocations = async (data) => {
  try {
    const result = await axios.post(API_URL.changeLocationsUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};
