import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import FieldHead from "../../../../shared/utils/tableField/FieldHead";
import FieldBody from "../../../../shared/utils/tableField/FieldBody";
import TablePagination from "@mui/material/TablePagination";
import { Tooltip } from "@mui/material";
import EmptyTable from "../../../../shared/utils/emptyTable/EmptyTable";
import { useTranslation } from "react-i18next";

export default function TotalProduct({ products, tableRef }) {
  const {t} = useTranslation()
  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event, newPage) => {
  //   setPage(newPage);
  // };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };
  return (
    <TableContainer>
      <Table sx={{ minWidth: 485 }} aria-label="simple table" ref={tableRef}>
        <TableHead className="mi_table__head">
          <TableRow>
            <TableCell>
              <FieldHead title={t("product_category")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("product_name")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("product_id")} />
            </TableCell>

            <TableCell>
              <FieldHead title={t("manufacturer")} />
            </TableCell>
            <TableCell>
              <FieldHead title={t("unit_of_measure")} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products ? (
            <>
              {products?.length > 0 ? (
                <>
                  {products?.map((row) => (
                    <TableBodyRow row={row} key={row?._id} />
                  ))}
                </>
              ) : (
                <TableRow className="mi_table__body_No_padding">
                  <TableCell style={{ padding: "2rem" }} colSpan={5}>
                    <EmptyTable />
                  </TableCell>
                </TableRow>
              )}
            </>
          ) : (
            <TableRow className="mi_table__body_No_padding">
              <TableCell style={{ padding: 0 }} colSpan={5} align="center">
                <div className="Loading_message">
                  <p className="page__note_fs">{t("loading")}...</p>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {/* <TablePagination
        component='div'
        count={100}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </TableContainer>
  );
}

function TableBodyRow({ row }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const copyToClipboard = (copy) => {
    if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
      setTooltipOpen(true);
      return navigator.clipboard.writeText(copy);
    }
    return Promise.reject(
      "This browser does not support the Clipboard API. Please use a modern browser!"
    );
  };
  return (
    <React.Fragment>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell>
          <FieldBody text={row?.type} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.name} />
        </TableCell>
        <TableCell>
          <div className="table__field_two_data">
            <FieldBody text={row?.id} />
            <Tooltip
              title="Copied"
              open={tooltipOpen}
              leaveDelay={1000}
              onClose={() => setTooltipOpen(false)}
            >
              <div
                className="copy_icon mi_link"
                onClick={() => copyToClipboard(row?.id)}
              >
                <i className="fa-regular fa-copy"></i>
              </div>
            </Tooltip>
          </div>
        </TableCell>

        <TableCell>
          <FieldBody text={row?.manufacturers[0]?.details?.name} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.units} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
