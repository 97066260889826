import React from 'react';
import './AnalyticCard.css';

export default function AnalyticCard({
  icon,
  title,
  number,
  value,
  label,
  labelType,
  state,
  tab,
  setTab,
  nonClickable,
  size,
}) {
  return (
    <React.Fragment>
      <div
        className={`AnalyticCard__card ${state && tab === state && 'active'} ${
          nonClickable ? 'nonClickable' : 'Clickable'
        }`}
        onClick={() => {
          if (state && setTab) {
            setTab(state);
          }
        }}
      >
        <div className={`AnalyticCard__header ${size}`}>
          {icon && <img src={icon} alt='icon' className='Analytic__icon' />}

          <h1 className='AnalyticCard__title_fs'>{title}</h1>
        </div>
        <div className='AnalyticCard__body'>
          {value != null ? (
            <div className='AnalyticCard__number_flex'>
              <h1 className='AnalyticCard__second_number_fs'>${value} USD</h1>
              {/* <h2 className='AnalyticCard__second_number_fs'>
                {number ? number : 0} Dose(s)
              </h2> */}
            </div>
          ) : (
            <h1 className='AnalyticCard__number_fs'>{number ? number : 0}</h1>
          )}

          {label && (
            <div className={`AnalyticCard__label ${labelType}`}>
              <p className='AnalyticCard__label_fs '>{label}</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
