import { Chip } from '@mui/material';
import React from 'react';

export default function LocationList({ data, values, setFieldValue }) {
  const handleDelete = (id) => {
    const newLocations = values?.location?.filter((loc) => loc?._id !== id);
    setFieldValue('location', newLocations);
  };
  return (
    <Chip
      label={`${data?.orgLevel} - ${data?.name}`}
      onDelete={() => handleDelete(data?._id)}
    />
  );
}
