import React, { useEffect, useState } from 'react';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import SwitchButton from '../../../common/switchButton/SwitchButton';
import TablePagination from '@mui/material/TablePagination';
import { modifyUsers } from '../../../../redux/userActions';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import toast from 'react-hot-toast';
import { getRoles } from '../../../../redux/userActions';
import UpdateUser from '../../../components/updateUser/UpdateUsers';
import { useTranslation } from 'react-i18next';

export default function TotalUsers({
  data,
  analyticUpdated,
  setAnalyticUpdated,
  pagination,
  page,
  setPage,
  limit,
  setLimit,
  CurrentTab,
  tableRef,
}) {
  const { t } = useTranslation();
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setLimit(parseInt(event.target.value, 10));
    setPage(0);
  };
  const [roles, setRoles] = useState();
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const r = await getRoles();
    setRoles(r.data);
  };

  const dataValue = data?.filter((d) => d?.locationIds?.length > 1);

  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 486 }} aria-label='simple table' ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('user') + ' / ' + t('org_level')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('email_id')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('phone_number')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('location')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('role')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('status')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('created_on')} />
              </TableCell>
              {!CurrentTab && (
                <TableCell>
                  <FieldHead title={t('action')} />
                </TableCell>
              )}
              <TableCell>
                <FieldHead title={t('update_user')} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {data ? (
              <>
                {data?.length > 0 ? (
                  <>
                    {data?.map((row, index) => (
                      <TableRowCard
                        row={row}
                        key={index}
                        i={index}
                        roles={roles}
                        analyticUpdated={analyticUpdated}
                        setAnalyticUpdated={setAnalyticUpdated}
                        data={data}
                        CurrentTab={CurrentTab}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={8}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={8} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t('loading')}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
        <TablePagination
          component='div'
          count={pagination?.totalRecords}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={limit}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </React.Fragment>
  );
}

function TableRowCard({
  row,
  i,
  analyticUpdated,
  setAnalyticUpdated,
  data,
  roles,
  CurrentTab,
}) {
  const [checked, setChecked] = React.useState();

  const [user, setUser] = React.useState();
  const [openPopup, setOpenPopup] = useState(false);

  useEffect(() => {
    if (row?.accountStatus == 'ACTIVE') {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [data]);

  const handleOpenPopup = (row) => {
    setUser(row);
    setOpenPopup(true);
  };

  const handleUpdateuserClosePopup = () => {
    setOpenPopup(false);
    setUser(null);
  };

  const handleCheckedChange = async (event) => {
    setChecked(event.target.checked);
    row.accountStatus = event.target.checked ? 'ACTIVE' : 'INACTIVE';
    await modifyUsers({
      userId: row._id,
      status: event.target.checked ? 'ACTIVE' : 'INACTIVE',
    });
    setAnalyticUpdated((prev) => !prev);
    toast.success('User updated');
  };

  const districtNames = row?.locations
    .map((district) => district.name)
    .join(', ');

  return (
    <React.Fragment>
      <TableRow
        key={i}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <FieldBody
            text={row.firstName + ' ' + row.lastName}
            subtext={row.orgLevel}
            color='bold'
          />
        </TableCell>
        <TableCell>
          <FieldBody text={row.emailId} />
        </TableCell>
        <TableCell>
          {row.phoneNumber ? (
            <FieldBody text={row.phoneNumber} />
          ) : (
            <FieldBody text={'---'} />
          )}
        </TableCell>
        <TableCell>
          <FieldBody text={districtNames} />
        </TableCell>
        <TableCell>
          <FieldBody text={row?.role?.name} />
        </TableCell>
        <TableCell>
          {row?.accountStatus === 'ACTIVE' ? (
            <StatusLabel status={row.accountStatus} tone='active' />
          ) : (
            <StatusLabel status={row.accountStatus} tone='grey' />
          )}
        </TableCell>
        <TableCell>
          <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
        </TableCell>
        {!CurrentTab && (
          <TableCell>
            <span
              className={
                row?.accountStatus == 'PENDING' ? 'disableElement' : ''
              }
            >
              <SwitchButton
                value={checked}
                handleChange={handleCheckedChange}
              />
            </span>
          </TableCell>
        )}
        <TableCell align='center'>
          {row?.accountStatus !== 'PENDING' && (
            <div onClick={() => handleOpenPopup(row)}>
              {user?._id === row?._id ? (
                <i
                  className='fa-solid fa-pencil fa-xs'
                  style={{ color: '#c3ccd3' }}
                ></i>
              ) : (
                <i className='fa-solid fa-pencil fa-xs'></i>
              )}
            </div>
          )}
        </TableCell>
      </TableRow>

      {user && (
        <UpdateUser
          keepMounted
          open={openPopup}
          roles={roles}
          onClose={handleUpdateuserClosePopup}
          user={row}
          analyticUpdated={analyticUpdated}
          setAnalyticUpdated={setAnalyticUpdated}
        />
      )}
    </React.Fragment>
  );
}
