import React from 'react';
import './StatusLabel.css';

export default function StatusLabel({ status, tone }) {
  const toneColor = tone?.toLowerCase();

  let statusText = status;

  if (status?.includes('_')) {
    const separatedStrings = status?.split('_');

    statusText = separatedStrings;
  } else {
    statusText = status;
  }
  return (
    <React.Fragment>
      <div className={`StatusLabel ${toneColor}`}>
        {Array.isArray(statusText) ? (
          <>
            {statusText?.map((str, index) => (
              <p key={index} className='StatusLabel__title_fs'>
                {str?.toLowerCase()}
              </p>
            ))}
          </>
        ) : (
          <p className='StatusLabel__title_fs'>
            {statusText && statusText?.toLowerCase()}
          </p>
        )}
      </div>
    </React.Fragment>
  );
}
