import React, { useCallback, useRef } from 'react';
import './ManageOrganization.css';
import TotalOrgs from './totalOrgs/TotalOrgs';
import AddLocation from '../../components/addLocation/AddLocation';
import ViewLocation from '../../components/viewLocation/ViewLocation';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import Import from '/icons/all/Import.svg';
import Export from '/icons/all/Export.svg';
import Search from '/icons/all/Search.svg';
import {
  adminUsers,
  adminLocations,
  getAdminOrgLevels,
} from '../../../redux/userActions';
import { formatNumber } from '../../../shared/common/commonFunctions';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import DataExport from '../../../shared/dataExport/DataExport';
import TotalOrgExport from './exportTables/TotalOrgExport';
import LocationExport from './exportTables/LocationExport';

export default function ManageOrganization() {
  const [openAddLocationPopup, setOpenAddLocationPopup] = React.useState(false);
  const [orgLevels, setOrgLevels] = React.useState();
  const [orgLvls, setOrgLvls] = React.useState();
  const [locations, setLocations] = React.useState();
  const [analyticUpdated, setAnalyticUpdated] = React.useState(false);
  const [users, setUsers] = React.useState();
  const [CurrentLevel, setCurrentLevel] = React.useState('');
  const { t } = useTranslation();

  const handleOpenAddLocationPopup = () => {
    setOpenAddLocationPopup(true);
  };

  const handleCloseAddLocationPopup = () => {
    setOpenAddLocationPopup(false);
  };

  const [openLocationPopup, setOpenLocationPopup] = React.useState(false);
  const handleOpenLocationPopup = () => {
    setOpenLocationPopup(true);
  };

  const handleCloseLocationPopup = () => {
    setOpenLocationPopup(false);
  };

  const fetchData = async () => {
    const res = await getAdminOrgLevels();
    setOrgLevels(res.data);
    setOrgLvls(res.data);
    const re = await adminLocations();
    setLocations(re?.data?.data);
    const r = await adminUsers();
    setUsers(r.data?.data);
  };

  const SearchList = (keyword) => {
    if (!keyword) {
      setOrgLvls(orgLevels);
      return;
    }

    const fuse = new Fuse(orgLevels, {
      keys: ['name', 'isActive', 'value'],
    });

    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setOrgLvls(searchResult);
    } else {
      setOrgLvls([]);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, [analyticUpdated]);

  const [openPrint, setOpenPrint] = React.useState(false);
  const [printData, setPrintData] = React.useState(false);
  const [printTab, setPrintTab] = React.useState(false);

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async (page, org) => {
    if (page === 'org') {
      setPrintData(orgLevels);
      setPrintTab('org');
      setOpenPrint(true);
    } else if (page === 'locations') {
      const re = await adminLocations(org);
      setPrintData(re?.data?.data);
      setPrintTab('locations');
      setOpenPrint(true);
    }
  };

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(
        wb,
        `totalOrganizations_${dayjs().format('DD-MM-YYYY')}.xlsx`
      );
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, []);
  const tableLocationRef = useRef();
  const xportLocations = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableLocationRef.current);
      XLSX.writeFile(wb, `totalLocations_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, []);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={handleOpenAddLocationPopup}
            >
              <i className='fa-solid fa-plus'></i>
              <span>{t('add_locations')}</span>
            </button>
            <button className='mi_btn mi_btn_medium mi_btn_outline'>
              <img src={Import} className='Search__icon' />
              <span>{t('import')}</span>
            </button>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search_by_level')}
                onInput={(e) => {
                  SearchList(e.target.value);
                }}
              />
            </article>
            <button
              onClick={() => openPrintPopup('org', CurrentLevel)}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              <img src={Export} style={{ height: '18px' }} />
              <span>{t('export')}</span>
            </button>
          </div>
        </div>
        <div className='Page__Analytic_list'>
          <AnalyticCard
            title={t('total_no_of_org_levels')}
            number={formatNumber(orgLevels?.length)}
            // label="5 New"
            labelType='blue_label'
            nonClickable={true}
          />
          <AnalyticCard
            title={t('total_no_of_locations')}
            number={formatNumber(locations?.length)}
            // label="20 Online"
            labelType='green_label'
            nonClickable={true}
          />
          <AnalyticCard
            title={t('total_no_of_users')}
            number={formatNumber(users?.length)}
            nonClickable={true}
          />
        </div>

        <div className='Page__table_space'>
          <TotalOrgs
            orgLevels={orgLvls}
            users={users}
            locations={locations}
            handleLocation={handleOpenLocationPopup}
            setCurrentLevel={setCurrentLevel}
            tableRef={tableRef}
            openPrintPopup={openPrintPopup}
          />
        </div>
      </section>

      <ViewLocation
        keepMounted
        open={openLocationPopup}
        locations={locations}
        setLocations={setLocations}
        orgLevels={orgLevels}
        onClose={handleCloseLocationPopup}
        CurrentLevel={CurrentLevel}
        openPrintPopup={openPrintPopup}
      />
      <AddLocation
        keepMounted
        open={openAddLocationPopup}
        orgLevels={orgLevels}
        locations={locations}
        onClose={handleCloseAddLocationPopup}
        analyticUpdated={analyticUpdated}
        setAnalyticUpdated={setAnalyticUpdated}
      />
      <DataExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <>
            {printTab === 'org' ? (
              <TotalOrgExport
                tableRef={tableRef}
                printData={printData}
                locations={locations}
                users={users}
              />
            ) : (
              <LocationExport
                tableRef={tableLocationRef}
                printData={printData}
              />
            )}
          </>
        }
        title={printTab === 'org' ? 'Level List' : 'Location List'}
        printData={printData}
        xport={printTab === 'org' ? xport : xportLocations}
      />
    </React.Fragment>
  );
}
