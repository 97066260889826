import { API_URL } from "../config";
import axios from "axios";

export const getInBoundOrders = async (status, page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.inBoundOrdersUrl, {
      params: { status: status, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getInboundOrdersAnalytics = async (id) => {
  try {
    const result = await axios.get(API_URL.inBoundOrdersAnalyticsUrl, {
      params: { locationId: id },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getOutboundOrders = async (
  status,
  isTransfer,
  page = 0,
  limit = 5,
) => {
  try {
    const result = await axios.get(API_URL.outBoundOrdersUrl, {
      params: {
        status: status,
        isTransfer: isTransfer,
        page: page + 1,
        limit: limit,
      },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getOutboundOrdersAnalytics = async (id) => {
  try {
    const result = await axios.get(API_URL.outBoundOrdersAnalyticsUrl, {
      params: { locationId: id },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getOrderDetails = async (id) => {
  try {
    const result = await axios.get(API_URL.orderDetailsUrl + id);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const respondOrder = async (data) => {
  try {
    const result = await axios.post(API_URL.respondOrderUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createOrder = async (data) => {
  try {
    const result = await axios.post(API_URL.createOrderUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateOrder = async (data) => {
  try {
    const result = await axios.put(API_URL.createOrderUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const cancelOrder = async (data) => {
  try {
    const result = await axios.patch(API_URL.cancelOrderUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getProductType = async () => {
  try {
    const result = await axios.get(API_URL.productTypesUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getManufacturers = async () => {
  try {
    const result = await axios.get(API_URL.manufacturersUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getUserLocationInfo = async () => {
  try {
    const result = await axios.get(API_URL.userLocationInfoUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};
