import React, { useCallback, useRef, useState } from 'react';
import './ManageUsers.css';
import TotalUsers from './totalUsers/TotalUsers';
import PendingUsers from './pendingUsers/PendingUsers';
import RejectedUsers from './rejectedUsers/RejectedUsers';
import AddUser from '../../components/addUser/AddUser';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import Import from '/icons/all/Import.svg';
import Search from '/icons/all/Search.svg';
import Export from '/icons/all/Export.svg';
import {
  adminUsers,
  adminDashboardSummary,
  getAdminOrgLevels,
  getRoles,
} from '../../../redux/userActions';
import { formatNumber } from '../../../shared/common/commonFunctions';
import Fuse from 'fuse.js';
import { useTranslation } from 'react-i18next';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import DataExport from '../../../shared/dataExport/DataExport';
import TotalUserExport from './exportTables/TotalUserExport';

export default function ManageUsers() {
  const [CurrentTab, setCurrentTab] = useState('totalUsers');
  const [summary, setSummary] = useState();
  const [data, setData] = React.useState();
  const [allData, setAllData] = React.useState();
  const [orgLevels, setOrgLevels] = React.useState();
  const [roles, setRoles] = React.useState();
  const [analyticUpdated, setAnalyticUpdated] = React.useState(false);
  const { t } = useTranslation();

  const [openPopup, setOpenPopup] = React.useState(false);
  const [pagination, setPagination] = useState({
    recordsPerPage: 0,
    totalRecords: 0,
    currentPage: 0,
  });
  const [page, setPage] = React.useState(0);
  const [limit, setLimit] = React.useState(10);
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleAdduserClosePopup = () => {
    setOpenPopup(false);
  };

  const status =
    (CurrentTab === 'activeUsers' && 'ACTIVE') ||
    (CurrentTab === 'inactiveUsers' && 'INACTIVE') ||
    (CurrentTab === 'pendingUsers' && 'PENDING') ||
    (CurrentTab === 'rejectedUsers' && 'REJECTED');

  React.useEffect(() => {
    setPage(0);
  }, [CurrentTab]);

  React.useEffect(() => {
    const fetchData = async () => {
      const res = await adminUsers(status ? status : null, page, limit);
      setData(res.data?.data);
      setPagination({
        recordsPerPage: res?.data?.recordsPerPage,
        totalRecords: res?.data?.totalRecords,
        currentPage: res?.data?.currentPage,
      });
      setAllData(res.data?.data);
      const result = await adminDashboardSummary();
      setSummary(result?.data);
      const resp = await getAdminOrgLevels();
      setOrgLevels(resp.data);
      const r = await getRoles();
      setRoles(r.data);
    };
    fetchData();
  }, [analyticUpdated, CurrentTab, page, limit, status]);

  const SearchList = (keyword) => {
    if (!keyword) {
      setData(allData);
      return;
    }

    const fuse = new Fuse(allData, {
      keys: ['firstName', 'lastName', 'emailId', 'phoneNumber', 'orgLevel'],
    });

    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setData(searchResult);
    } else {
      setData([]);
    }
  };

  const [openPrint, setOpenPrint] = React.useState(false);
  const [printData, setPrintData] = React.useState(false);

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async () => {
    const status =
      (CurrentTab === 'activeUsers' && 'ACTIVE') ||
      (CurrentTab === 'inactiveUsers' && 'INACTIVE') ||
      (CurrentTab === 'pendingUsers' && 'PENDING') ||
      (CurrentTab === 'rejectedUsers' && 'REJECTED');

    const res = await adminUsers(status ? status : null, 0, 0);
    setPrintData(res.data?.data);
    setOpenPrint(true);
  };

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `${CurrentTab}_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, [CurrentTab]);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={handleOpenPopup}
            >
              <i className='fa-solid fa-plus'></i>
              <span>{t('add_users')}</span>
            </button>
            <button className='mi_btn mi_btn_medium mi_btn_outline'>
              {/* <i className='fa-solid fa-download'></i> */}
              <img src={Import} className='Search__icon' />
              <span>{t('import')}</span>
            </button>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              {/* <i className='fa-solid fa-magnifying-glass'></i> */}
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search_users')}
                onInput={(e) => {
                  SearchList(e.target.value);
                }}
              />
            </article>
            <button
              onClick={openPrintPopup}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              <img src={Export} style={{ height: '18px' }} />
              <span>{t('export')}</span>
            </button>
          </div>
        </div>
        <div className='Page__Analytic_list'>
          <AnalyticCard
            state='totalUsers'
            title={t('total_no_of_users')}
            number={formatNumber(summary?.totalUsers)}
            // label="5 New"
            labelType='blue_label'
            tab={CurrentTab}
            setTab={setCurrentTab}
          />
          <AnalyticCard
            state='activeUsers'
            title={t('active_users')}
            number={formatNumber(summary?.activeUsers)}
            // label="20 Online"
            labelType='green_label'
            tab={CurrentTab}
            setTab={setCurrentTab}
          />
          <AnalyticCard
            state='inactiveUsers'
            title={t('inactive_users')}
            number={formatNumber(summary?.inActiveUsers)}
            tab={CurrentTab}
            setTab={setCurrentTab}
          />
          <AnalyticCard
            state='pendingUsers'
            title={t('pending_requests')}
            number={formatNumber(summary?.pendingUsers)}
            tab={CurrentTab}
            setTab={setCurrentTab}
          />
          <AnalyticCard
            state='rejectedUsers'
            title={t('rejected_requests')}
            number={formatNumber(summary?.rejectedUsers)}
            tab={CurrentTab}
            setTab={setCurrentTab}
          />
        </div>

        <div className='Page__table_space'>
          {CurrentTab === 'totalUsers' && (
            <TotalUsers
              data={data}
              analyticUpdated={analyticUpdated}
              setAnalyticUpdated={setAnalyticUpdated}
              pagination={pagination}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              CurrentTab={CurrentTab}
              tableRef={tableRef}
            />
          )}
          {CurrentTab === 'activeUsers' && (
            <TotalUsers
              data={data}
              analyticUpdated={analyticUpdated}
              setAnalyticUpdated={setAnalyticUpdated}
              pagination={pagination}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              tableRef={tableRef}
            />
          )}
          {CurrentTab === 'inactiveUsers' && (
            <TotalUsers
              data={data}
              analyticUpdated={analyticUpdated}
              setAnalyticUpdated={setAnalyticUpdated}
              pagination={pagination}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              tableRef={tableRef}
            />
          )}
          {CurrentTab === 'pendingUsers' && (
            <PendingUsers
              roles={roles}
              orgLevels={orgLevels}
              data={data}
              setAnalyticUpdated={setAnalyticUpdated}
              pagination={pagination}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              tableRef={tableRef}
            />
          )}
          {CurrentTab === 'rejectedUsers' && (
            <RejectedUsers
              data={data}
              pagination={pagination}
              setAnalyticUpdated={setAnalyticUpdated}
              page={page}
              setPage={setPage}
              limit={limit}
              setLimit={setLimit}
              tableRef={tableRef}
            />
          )}
        </div>
      </section>

      <AddUser
        keepMounted
        open={openPopup}
        roles={roles}
        onClose={handleAdduserClosePopup}
        analyticUpdated={analyticUpdated}
        setAnalyticUpdated={setAnalyticUpdated}
      />

      <DataExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <TotalUserExport tableRef={tableRef} printData={printData} />
        }
        title='User List'
        printData={printData}
        xport={xport}
      />
    </React.Fragment>
  );
}
