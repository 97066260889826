import React, { useState } from 'react';

// MUI Imports
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { DialogActions, DialogTitle } from '@mui/material';
import LocationTable from './locationTable/LocationTable';
import { useTranslation } from 'react-i18next';
import Export from '/icons/all/Export.svg';

export default function ViewLocation(props) {
  const {
    onClose,
    open,
    locations,
    setLocations,
    orgLevels,
    CurrentLevel,
    openPrintPopup,
    ...other
  } = props;

  const { t } = useTranslation();
  const [SearchKeyword, setSearchKeyword] = useState('');

  const ViewLevelLocation = locations?.filter(
    (loc) => loc.orgLevel === CurrentLevel
  );

  let LocationData = ViewLevelLocation;

  if (SearchKeyword) {
    LocationData = ViewLevelLocation?.filter((org) =>
      org.name.toLowerCase().includes(SearchKeyword.toLowerCase())
    );
  } else {
    LocationData = ViewLevelLocation;
  }

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': { width: '100%' },
      }}
      className='ModalPopup__dialog'
      maxWidth='md'
      open={open}
      {...other}
    >
      <DialogTitle sx={{ padding: '0 !important' }}>
        <div className='ModalPopup__header'>
          <div className='ModalPopup__header__title_fs'>
            {t('location_details')}
          </div>
          <div className='ModalPopup__close_icon' onClick={onClose}>
            <i className='fa-solid fa-xmark'></i>
          </div>
        </div>
      </DialogTitle>
      <DialogContent
        sx={{ padding: '0 !important', borderRadius: '0.6rem !important' }}
      >
        <div className='ModalPopup__body'>
          <section className='Page__main_wrapper'>
            <div className='Page__action_wrapper'>
              <div className='Page__left_actions'></div>
              <div className='Page__right_actions'>
                <article className='mi_search_bar'>
                  <i className='fa-solid fa-magnifying-glass'></i>
                  <input
                    type='text'
                    placeholder={t('search')}
                    onInput={(e) => {
                      setSearchKeyword(e.target.value);
                    }}
                  />
                </article>
                <button
                  onClick={() => openPrintPopup('locations', CurrentLevel)}
                  className='mi_btn mi_btn_medium mi_btn_outline'
                >
                  <img src={Export} style={{ height: '18px' }} />
                  <span>{t('export')}</span>
                </button>
              </div>
            </div>

            <div className='Page__table_space'>
              <LocationTable locations={LocationData} />
            </div>
          </section>
        </div>
      </DialogContent>
      <DialogActions>
        <div className='ModalPopup__action_wrapper'>
          <div className='ModalPopup_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={onClose}
            >
              <span>{t('cancel')}</span>
            </button>
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}
