import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import FieldHead from '../../../../shared/utils/tableField/FieldHead';
import FieldBody from '../../../../shared/utils/tableField/FieldBody';
import StatusLabel from '../../../../shared/utils/statusLabel/StatusLabel';
import EmptyTable from '../../../../shared/utils/emptyTable/EmptyTable';
import SwitchButton from '../../../common/switchButton/SwitchButton';
import Information from '/favicon/information.png';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import Export from '/icons/all/Export.svg';

export default function TotalOrgs({
  handleLocation,
  orgLevels,
  locations,
  users,
  setCurrentLevel,
  tableRef,
  openPrintPopup,
}) {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <TableContainer>
        <Table sx={{ minWidth: 485 }} aria-label='simple table' ref={tableRef}>
          <TableHead className='mi_table__head'>
            <TableRow>
              <TableCell>
                <FieldHead title={t('organization_level')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('total_users')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('no_of_locations')} />
              </TableCell>

              <TableCell>
                <FieldHead title={t('status')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('created_on')} />
              </TableCell>
              <TableCell>
                <FieldHead title={t('action')} />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='mi_table__body'>
            {orgLevels ? (
              <>
                {orgLevels?.length > 0 ? (
                  <>
                    {orgLevels?.map((row, index) => (
                      <TableRowCard
                        row={row}
                        key={index}
                        users={users}
                        i={index}
                        locations={locations}
                        handleLocation={handleLocation}
                        setCurrentLevel={setCurrentLevel}
                        openPrintPopup={openPrintPopup}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow className='mi_table__body_No_padding'>
                    <TableCell style={{ padding: '2rem' }} colSpan={6}>
                      <EmptyTable />
                    </TableCell>
                  </TableRow>
                )}
              </>
            ) : (
              <TableRow className='mi_table__body_No_padding'>
                <TableCell style={{ padding: 0 }} colSpan={6} align='center'>
                  <div className='Loading_message'>
                    <p className='page__note_fs'>{t('loading')}...</p>
                  </div>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
}

function TableRowCard({
  row,
  handleLocation,
  i,
  users,
  locations,
  setCurrentLevel,
  openPrintPopup,
}) {
  const [checked, setChecked] = React.useState(true);

  const handleCheckedChange = (event) => {
    setChecked(event.target.checked);
  };
  const stopPropagation = (event) => {
    event.stopPropagation();
  };
  const PopupOpenHandler = () => {
    handleLocation();
    setCurrentLevel(row?.value);
  };
  return (
    <React.Fragment>
      <TableRow
        key={row.id}
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          <FieldBody text={row?.name} />
        </TableCell>
        <TableCell>
          <FieldBody
            text={users?.filter((u) => u.orgLevel == row?.value)?.length}
          />
        </TableCell>
        <TableCell>
          <div className='Location_field'>
            <FieldBody
              text={locations?.filter((l) => l.orgLevel == row?.value)?.length}
            />
            <div className='location_icon_group'>
              <Tooltip title={'View'} arrow>
                <img
                  src={Information}
                  alt='Icon'
                  style={{ marginLeft: '-1rem' }}
                  onClick={PopupOpenHandler}
                  className='mi_table__row_clickable'
                />
              </Tooltip>
              <Tooltip title={'Export'} arrow>
                <img
                  src={Export}
                  alt='Icon'
                  style={{ marginLeft: '-1rem' }}
                  onClick={() => {
                    openPrintPopup('locations', row?.value);
                  }}
                  className='mi_table__row_clickable export_icon'
                />
              </Tooltip>
            </div>
          </div>
        </TableCell>

        <TableCell>
          {row.isActive ? (
            <StatusLabel status='Active' tone='active' />
          ) : (
            <StatusLabel status='Pending' tone='grey' />
          )}
        </TableCell>
        <TableCell>
          <FieldBody text={new Date(row?.createdAt).toLocaleDateString()} />
        </TableCell>
        <TableCell onClick={stopPropagation}>
          <SwitchButton value={checked} handleChange={handleCheckedChange} />
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
