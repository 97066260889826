import { API_URL } from '../config';
import axios from 'axios';

export const getInventoryValue = async () => {
  try {
    const result = await axios.get(API_URL.inventoryProductValue);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getInventories = async () => {
  try {
    const result = await axios.get(API_URL.getInventories);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getInventory = async (page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.inventoryUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getInventoryAnalytics = async (id) => {
  try {
    const result = await axios.get(API_URL.inventoryAnalyticsUrl, {
      params: { locationId: id },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const currentInventoryStock = async (type, page = 0, limit = 5) => {
  try {
    const result = await axios.get(API_URL.currentInventoryStockUrl, {
      params: { type: type, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const outOfStockInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.outOfStockInventoryUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getAllInventoryProducts = async (
  id,
  type,
  page = 0,
  limit = 20
) => {
  try {
    const result = await axios.get(API_URL.getAllInventoryProducts, {
      params: { locationId: id, type: type, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const nearExpiryInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.nearExpiryInventoryUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const expiredInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.expiredInventoryUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const rejectedInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.rejectedInventoryUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const wastedInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.wastedUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const quarantineInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.quarantineUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const disposedInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.disposedUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const addInventory = async (data) => {
  try {
    const result = await axios.post(API_URL.addInventoryUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getBatchByProduct = async (id, status, page, limit) => {
  try {
    const result = await axios.get(API_URL.batchesOfProductUrl + id, {
      params: { status: status, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getBatchRecall = async (id, batchNo, locationId, page, limit) => {
  try {
    const result = await axios.get(API_URL.batchesOfProductUrl + id, {
      params: {
        batchNo: batchNo,
        locationId: locationId,
        page: page + 1,
        limit: limit,
      },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createVar = async (data) => {
  try {
    const result = await axios.post(API_URL.createVarUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getVarList = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.getVarListUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getVar = async (id) => {
  try {
    const result = await axios.get(`${API_URL.getVarListUrl}/${id}`);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateVAR = async (data) => {
  try {
    const result = await axios.patch(API_URL.getVarListUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getUserLocations = async () => {
  try {
    const result = await axios.get(API_URL.userLocationsUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getLocationProducts = async (id, page, limit, type) => {
  try {
    const result = await axios.get(API_URL.addInventoryUrl, {
      params: { locationId: id, page: page + 1, limit: limit, type: type },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getAirportInfo = async (code, international = true) => {
  try {
    const result = await axios.get(`${API_URL.getAirportInfo}/${code}`, {
      params: { international },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const addBeneficiaries = async (data) => {
  try {
    const result = await axios.post(API_URL.beneficiariesUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getBeneficiaries = async (today, page, limit) => {
  try {
    const result = await axios.get(API_URL.beneficiariesUrl, {
      params: { today: today, page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getBeneficiariesAnalytics = async () => {
  try {
    const result = await axios.get(API_URL.beneficiariesAnalyticsUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getUtilized = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.unitUtilized, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createPar = async (data) => {
  try {
    const result = await axios.post(API_URL.parUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updatePAR = async (data) => {
  try {
    const result = await axios.patch(API_URL.parUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getParList = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.parUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getPar = async (id) => {
  try {
    const result = await axios.get(`${API_URL.parUrl}/${id}`);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

// Cold Chain
export const getEquipmentList = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.getEquipmentList, {
      params: {
        page: page + 1,
        limit: limit,
      },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getMaintenanceJobsList = async () => {
  try {
    const result = await axios.get(API_URL.coldChainUrl);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createMaintenanceJob = async (data) => {
  try {
    const result = await axios.post(API_URL.coldChainUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateMaintenanceJob = async (id, data) => {
  try {
    const result = await axios.patch(`${API_URL.coldChainUrl}/${id}`, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createTemperature = async (data) => {
  try {
    const result = await axios.post(API_URL.temperatureUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getTemperatureHistory = async (id, date) => {
  try {
    const result = await axios.get(`${API_URL.temperatureUrl}/${id}`, {
      params: {
        date,
      },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const recallInventory = async (data) => {
  try {
    const result = await axios.post(API_URL.recallUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getRecallInventory = async (page, limit) => {
  try {
    const result = await axios.get(API_URL.recallUrl, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getRecallBatches = async (
  id,
  manufacturerId,
  batchNo,
  page,
  limit
) => {
  try {
    const result = await axios.get(`${API_URL.recallUrl}/batches`, {
      params: {
        productId: id,
        manufacturerId: manufacturerId,
        batchNo: batchNo,
        page: page + 1,
        limit: limit,
      },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateVVmStatus = async (data) => {
  try {
    const result = await axios.put(API_URL.updateVvmStatusUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const createAdjustmentInventory = async (data) => {
  try {
    const result = await axios.post(API_URL.adjustmentUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const updateAdjustmentInventory = async (data) => {
  try {
    const result = await axios.patch(API_URL.adjustmentUrl, data);
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getAdjustmentInventoryInbound = async (page, limit) => {
  try {
    const result = await axios.get(`${API_URL.adjustmentUrl}/inbound`, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};

export const getAdjustmentInventoryOutbound = async (page, limit) => {
  try {
    const result = await axios.get(`${API_URL.adjustmentUrl}/outbound`, {
      params: { page: page + 1, limit: limit },
    });
    return result.data;
  } catch (e) {
    return e.response;
  }
};
