import { createSlice } from "@reduxjs/toolkit";
import { element } from "prop-types";

const initialState = {
  popupStatus: {
    open: false,
    type: "",
    message: "",
    action: "",
    title: "",
    page: "",
    element: "",
  },
};

const popupSlice = createSlice({
  name: "popup",
  initialState,
  reducers: {
    callPopup: (state, action) => {
      state.popupStatus = action.payload;
    },
    closePopup: (state) => {
      state.popupStatus = {
        open: false,
        type: "",
        message: "",
        action: "",
        title: "",
        page: "",
        element: "",
      };
    },
  },
});

export const { callPopup, closePopup } = popupSlice.actions;
export default popupSlice.reducer;
