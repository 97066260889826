import './AdminDashboard.css';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import AssignRole from '../../components/assignRole/AssignRole';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import Search from '/icons/all/Search.svg';
import {
  adminUsers,
  adminDashboardSummary,
  getOrganizationLevel,
  getRoles,
  modifyUsers,
} from '../../../redux/userActions';
import EmptyTable from '../../../shared/utils/emptyTable/EmptyTable';
import { formatNumber } from '../../../shared/common/commonFunctions';
import Fuse from 'fuse.js';
import toast from 'react-hot-toast';

export default function AdminDashboard() {
  const [pendingUsers, setPendingUsers] = React.useState();
  const [orgLevels, setOrgLevels] = React.useState();
  const [summary, setSummary] = React.useState();
  const [roles, setRoles] = React.useState();
  const [allUsers, setAllUsers] = React.useState();
  const [analyticUpdated, setAnalyticUpdated] = useState(false);
  const { t } = useTranslation();

  React.useEffect(() => {
    fetchData();
  }, [analyticUpdated]);

  const fetchData = async () => {
    const pendingUsersResult = await adminUsers('PENDING');
    setPendingUsers(pendingUsersResult.data?.data);
    setAllUsers(pendingUsersResult.data?.data);

    const re = await getOrganizationLevel();
    setOrgLevels(re?.data);

    const result = await adminDashboardSummary();
    setSummary(result.data);

    const r = await getRoles();

    setRoles(r.data);
  };

  // Search Function
  const SearchList = (keyword) => {
    if (!keyword) {
      setPendingUsers(allUsers);
      return;
    }

    const fuse = new Fuse(allUsers, {
      keys: ['firstName', 'lastName', 'emailId', 'phoneNumber', 'orgLevel'],
    });

    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setPendingUsers(searchResult);
    } else {
      setPendingUsers([]);
    }
  };

  const [openAssignRolePopup, setOpenAssignRolePopup] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);

  const handleOpenAssignRolePopup = (row) => {
    setSelectedUser(row);
    setOpenAssignRolePopup(true);
  };

  const handleCloseAssignRolePopup = () => {
    setOpenAssignRolePopup(false);
    close();
  };

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__Analytic_list'>
          <AnalyticCard
            title={t('total_no_of_org_levels')}
            number={formatNumber(orgLevels?.length)}
            // label="5 New"
            labelType='blue_label'
            nonClickable={true}
          />
          <AnalyticCard
            title={t('total_no_of_users')}
            number={formatNumber(summary?.totalUsers)}
            nonClickable={true}
          />
          <AnalyticCard
            title={t('active_users')}
            number={formatNumber(summary?.activeUsers)}
            nonClickable={true}
          />
          <AnalyticCard
            title={t('inactive_users')}
            number={formatNumber(summary?.inActiveUsers)}
            nonClickable={true}
          />
        </div>

        <div className='AdminDashboard__container'>
          <div className='Page__action_wrapper'>
            <div className='Page__left_actions'>
              <div className={`TabItem__card `}>
                <h1>{t('pending_approvals')} </h1>

                <div className='tab_analytics'>
                  <span>
                    {summary?.pendingUsers ? summary?.pendingUsers : 0}
                  </span>
                </div>
              </div>
            </div>
            <div className='Page__right_actions'>
              <article className='mi_search_bar'>
                {/* <i className='fa-solid fa-magnifying-glass'></i> */}
                <img src={Search} className='Search__icon' />
                <input
                  type='text'
                  placeholder={t('search_users')}
                  // onInput={(t) => {
                  //   setPendingUsers(
                  //     allUsers.filter((u) =>
                  //       (u.firstName + " " + u.lastName)
                  //         .toLowerCase()
                  //         .includes(t.target.value.toLowerCase())
                  //     )
                  //   );
                  // }}

                  onInput={(e) => {
                    SearchList(e.target.value);
                  }}
                />
              </article>
              {/* <button className="mi_btn mi_btn_medium mi_btn_outline">
                <i className="fa-solid fa-sliders"></i>
                <span>Filter</span>
              </button> */}
            </div>
          </div>

          {pendingUsers ? (
            <>
              {pendingUsers?.length > 0 ? (
                <div className='AdminDashboard__request_list'>
                  {pendingUsers?.map((row, i) => (
                    <RequestCard
                      close={fetchData}
                      orgLevels={orgLevels}
                      roles={roles}
                      row={row}
                      key={i}
                      handleOpenAssignRolePopup={handleOpenAssignRolePopup}
                      t={t}
                    />
                  ))}
                </div>
              ) : (
                <div className='AdminDashboard__empty_table'>
                  <EmptyTable />
                </div>
              )}
            </>
          ) : (
            <div className='AdminDashboard__loading'>
              <p className='page__note_fs'>{t('loading')}...</p>
            </div>
          )}
        </div>
      </section>

      <AssignRole
        orgLevels={orgLevels}
        roles={roles}
        userId={selectedUser?._id}
        userLevel={selectedUser?.orgLevel}
        isUpdated={openAssignRolePopup}
        keepMounted
        open={openAssignRolePopup}
        onClose={handleCloseAssignRolePopup}
        setAnalyticUpdated={setAnalyticUpdated}
      />
    </React.Fragment>
  );
}

function RequestCard({ row, close, handleOpenAssignRolePopup, t }) {
  const [message, setMessage] = React.useState('');

  const rejectUser = async (row) => {
    const res = await modifyUsers({
      userId: row?._id,
      status: 'REJECTED',
    });
    if (res.success == true) {
      toast.success('User request rejected!');
      close();
    } else {
      setMessage(res.data.message);
      setTimeout(() => {
        setMessage('');
      }, 2000);
    }
  };
  return (
    <React.Fragment>
      <div className='RequestCard__container'>
        <div className='RequestCard__row'>
          <div className='RequestCard_column'>
            <h1 className='RequestCard__title_fs'>{t('user_name')} :</h1>
          </div>
          <div className='RequestCard_column'>
            <h2 className='RequestCard__data_fs name text_caps'>
              {row.firstName + ' ' + row.lastName}
            </h2>
          </div>
        </div>
        {row?.emailId && (
          <div className='RequestCard__row'>
            <div className='RequestCard_column'>
              <h1 className='RequestCard__title_fs'>{t('email_id')} :</h1>
            </div>
            <div className='RequestCard_column'>
              <h2 className='RequestCard__data_fs'>{row?.emailId}</h2>
            </div>
          </div>
        )}

        {row?.phoneNumber && (
          <div className='RequestCard__row'>
            <div className='RequestCard_column'>
              <h1 className='RequestCard__title_fs'>{t('contact_no')} :</h1>
            </div>
            <div className='RequestCard_column'>
              <h2 className='RequestCard__data_fs'>{row?.phoneNumber}</h2>
            </div>
          </div>
        )}

        <div className='RequestCard__row'>
          <div className='RequestCard_column'>
            <h1 className='RequestCard__title_fs'>
              {t('organization_level')} :
            </h1>
          </div>
          <div className='RequestCard_column'>
            <h2 className='RequestCard__data_fs'>{row?.orgLevel}</h2>
          </div>
        </div>

        <div className='RequestCard__actions'>
          <div className='RequestCard__time'>
            <h1 className='RequestCard__time_fs'>
              {new Date(row?.createdAt).toLocaleDateString()}
            </h1>
          </div>
          <div className='RequestCard__actions_btns'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={() => handleOpenAssignRolePopup(row)}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{t('accept_button')}</span>
            </button>
            <button
              className='mi_btn mi_btn_medium mi_btn_outline'
              onClick={() => {
                rejectUser(row);
                // toast.success("User request rejected!");
              }}
            >
              <span style={{ whiteSpace: 'nowrap' }}>{t('reject_button')}</span>
            </button>
          </div>
        </div>
        {message && (
          <span className='redirection_links_main_fs'>{message}</span>
        )}
      </div>
    </React.Fragment>
  );
}
