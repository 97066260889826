import React, { useCallback, useRef } from 'react';
import TotalProduct from './totalProduct/TotalProduct';
import AddProduct from '../../components/addProduct/AddProduct';
import AnalyticCard from '../../../shared/utils/analyticCard/AnalyticCard';
import { getProducts } from '../../../redux/userActions';
import Fuse from 'fuse.js';
import { getManufacturers, getProductType } from '../../../redux/orderActions';
import { formatNumber } from '../../../shared/common/commonFunctions';
import { useTranslation } from 'react-i18next';
import Import from '/icons/all/Import.svg';
import Search from '/icons/all/Search.svg';
import Export from '/icons/all/Export.svg';

import * as XLSX from 'xlsx';
import dayjs from 'dayjs';
import DataExport from '../../../shared/dataExport/DataExport';
import ExportProductList from './ExportProductList';

export default function ProductList() {
  const [openPopup, setOpenPopup] = React.useState(false);
  const [products, setProducts] = React.useState();
  const [allProducts, setAllProducts] = React.useState();
  const [productTypes, setProductTypes] = React.useState();
  const [manufacturers, setManufacturers] = React.useState();
  const { t } = useTranslation();
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    fetchData();
    setOpenPopup(false);
  };

  const fetchData = async () => {
    const res = await getProducts();
    setProducts(res.data);
    setAllProducts(res.data);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const productsResult = await getProducts();
      const manufacturersResult = await getManufacturers();
      const productTypeResult = await getProductType();
      setManufacturers(manufacturersResult?.data);
      setProductTypes(productTypeResult?.data);
      setProducts(productsResult?.data);
      setAllProducts(productsResult?.data);
    };
    fetchData();
  }, []);

  // Search Function
  const searchProductList = (keyword) => {
    if (!keyword) {
      setProducts(allProducts);
      return;
    }
    const fuse = new Fuse(allProducts, {
      keys: ['type', 'name', 'id', 'manufacturer.name', 'units.description'],
    });
    const result = fuse.search(keyword);
    const searchResult = [];
    if (result.length) {
      result.forEach((item) => {
        searchResult.push(item.item);
      });
      setProducts(searchResult);
    } else {
      setProducts([]);
    }
  };

  const [openPrint, setOpenPrint] = React.useState(false);

  const handleClosePrintPopup = () => {
    setOpenPrint(false);
  };

  const openPrintPopup = async () => {
    setOpenPrint(true);
  };

  const tableRef = useRef();
  const xport = useCallback(() => {
    try {
      const wb = XLSX.utils.table_to_book(tableRef.current);
      XLSX.writeFile(wb, `totalProducts_${dayjs().format('DD-MM-YYYY')}.xlsx`);
    } catch (error) {
      console.error('Export failed:', error);
    }
  }, []);

  return (
    <React.Fragment>
      <section className='Page__main_wrapper'>
        <div className='Page__action_wrapper'>
          <div className='Page__left_actions'>
            <button
              className='mi_btn mi_btn_medium mi_btn_primary'
              onClick={handleOpenPopup}
            >
              <i className='fa-solid fa-plus'></i>
              <span>{t('add_product')}</span>
            </button>
            <button className='mi_btn mi_btn_medium mi_btn_outline'>
              <img src={Import} className='Search__icon' />
              <span>{t('import')}</span>
            </button>
          </div>
          <div className='Page__right_actions'>
            <article className='mi_search_bar'>
              <img src={Search} className='Search__icon' />
              <input
                type='text'
                placeholder={t('search_by_products')}
                onInput={(e) => {
                  searchProductList(e.target.value);
                }}
              />
            </article>

            <button
              onClick={openPrintPopup}
              className='mi_btn mi_btn_medium mi_btn_outline'
            >
              <img src={Export} style={{ height: '18px' }} />
              <span>{t('export')}</span>
            </button>
          </div>
        </div>

        <div className='Page__Analytic_list'>
          <AnalyticCard
            title={t('total_product_category')}
            number={formatNumber(productTypes?.length)}
            nonClickable={true}
            labelType='blue_label'
          />
          <AnalyticCard
            title={t('total_product')}
            number={formatNumber(allProducts?.length)}
            nonClickable={true}
          />
          <AnalyticCard
            title={t('total_manufacturer')}
            number={formatNumber(manufacturers?.length)}
            nonClickable={true}
          />
        </div>

        <div className='Page__table_space'>
          <TotalProduct products={products} tableRef={tableRef} />
        </div>
      </section>
      <AddProduct
        keepMounted
        open={openPopup}
        onClose={handleClosePopup}
        products={products}
        manufacturers={manufacturers}
      />
      <DataExport
        open={openPrint}
        onClose={handleClosePrintPopup}
        tableComponent={
          <ExportProductList tableRef={tableRef} printData={products} />
        }
        title='Product List'
        printData={products}
        xport={xport}
      />
    </React.Fragment>
  );
}
