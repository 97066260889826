import React from 'react';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import { useField, useFormikContext } from 'formik';

const PhoneInputField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const handleChange = (value) => {
    setFieldValue(field.name, value);  
  };

  return (
    <div>
      <PhoneInput
        {...field}
        {...props}
        onChange={handleChange}
        value={field.value || ''}  
      />
    </div>
  );
};

export default PhoneInputField;
